import React, { Fragment, useEffect, useState } from "react";
import "./Blogs.css";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import Footer from "../Footer/Footer";
// import Navbar from "../Navbar/navbar";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import BlogSearch from "./BlogSearch";
import useWindowSize from "../../Hooks/useWindowSize";
import SuggestionCardBlogDetailPage from "./SuggestionCardBlogDetailPage";
import { BASE_URL } from "../../utils";
import NavbarNew from "../Navbar/NavbarNew";
import ThingsTodoSuggestions from "../DestinationPages/ThingsToDo/ThingsTodoDetail/ThingsTodoSuggestions";

function BlogsDetailPage({ sightseeing }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const destination = queryParams.get("destination");
  const { id } = useParams();
  const size = useWindowSize();
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}DataTransaction/${
            !sightseeing ? "Blogs" : "sightseeing"
          }?DestinationName=${destination}&${
            !sightseeing ? "PostId" : "SightId"
          }=${id}`
        );
        const fetchedBlogs = response?.data;
        setBlogs(fetchedBlogs);
      } catch (error) {
        console.error("Failed to fetch Blogs", error);
      }
    };

    fetchDestinations();
  }, [id]);

  return (
    <>
      <NavbarNew />
      <BlogSearch />
      <BlogImage vi={size.width > 468 ? false : true} data={blogs} />

      <div className="Content_Blogs">
        <div className="Blogs-Content">
          <div className="Blogs-Para">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {blogs?.Content}
            </ReactMarkdown>
          </div>
          <div className="Blogs-Para">
            <ul className="liststyle " style={{ listStyle: "disc" }}>
              {blogs?.Bullets?.map(
                (ele, id) =>
                  ele?.point.trim() !== "" && <li key={id}>{ele.point}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <SuggestionCardBlogDetailPage
        destination={destination}
        sightseeing={sightseeing}
      />
      {sightseeing && (
        <ThingsTodoSuggestions data={{ destination, sightseeing:blogs?.Name}} />
      )}

      <Footer />
    </>
  );
}

export default BlogsDetailPage;

export const BlogImage = ({ data, vi }) => {
  return (
    <div className="Blogs_parent">
      <div className="BlogheroImg">
        <img className="HeroBannerImage" src={data?.ImageURL} alt="" />
        {!vi && (
          <div className="darkContent">
            <p className="source_para">Source: {data?.Author}</p>
            <p className="head">{data?.Title}</p>

            <p className="minifooter">
              You may now need a visa if you’re travelling to{" "}
              {data?.DestinationName}.
            </p>
            <div className="btn-grpblog flex gap-4">
              <button>Travel</button>
              <button>Visa</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
