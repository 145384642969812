import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import './NewNavbar.css'

const DestinationListing = ({domesticDestinations, internationalDestinations, filterCategory, closePopupOnclick}) => {
  const navigate = useNavigate();
  const [currentLocation, setCurrentLocation] = useState(null);
    const navigateToLocation = (data) => {
        if (data === currentLocation) {
        //   setTimeout(() => setOpen(false), 100);
        } else if(filterCategory.category === 'DestinationName') {
          navigate(`/destination/${data.DestinationName}`)
          setCurrentLocation(data);
          closePopupOnclick()
        } else  {
          navigate(`/searchResult?DestinationName=${data.DestinationName}&${filterCategory.category}=${filterCategory.value}`);
          setCurrentLocation(data);
          closePopupOnclick()
        }
      };
    return (
    <>
    <div className="flex-col flex-[5] flex items-center">
          <h1 className="text-3xl relative right-9 font-bold">Domestic</h1>
          <div className="flex gap-2 flex-wrap">
            {domesticDestinations.map((data, index) => (
              <span
                onClick={() => navigateToLocation(data)}
                className="text-xl hover:text-red-700 cursor-pointer relative top-9 left-9 text-gray-800 font-bold"
                key={index}
                style={{ flex: "0 0 40%", maxWidth: "40%" }}
              >
                {data.DestinationName}
              </span>
            ))}
          </div>
        </div>
        <div className="w-[2px] h-full relative bg-gray-100"></div>
        <div className="flex-col flex-[5] flex items-center">
          <h1 className="text-3xl relative right-9 font-bold">International</h1>
          <div className="flex gap-1 flex-wrap">
            {internationalDestinations.map((data, index) => (
              <span
                onClick={() => navigateToLocation(data)}
                className="text-xl cursor-pointer hover:text-red-700 relative top-9 left-9 text-gray-800 font-bold"
                key={index}
                style={{ flex: "0 0 40%", maxWidth: "40%" }}
              >
                {data.DestinationName}
              </span>
            ))}
          </div>
        </div>
    </>
  );
};

export default DestinationListing;
