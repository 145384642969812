import React from "react";

const Testimonialmob = ({ data }) => {
  const ratting = [1, 2, 3, 4, 5];
  const handleNavigate = (link) => {
    if (link) {
      window.open(link, "_blank");
    }
  };
  return (
    <div
      onClick={() => handleNavigate(data?.Link)}
      target="blank"
      className="TestimonialsMobCardDiv"
    >
      <div className="testimonialsCard">
        <img className="" src={data?.TravelerImage} alt="" />
      </div>
      <div className="Testimonials-review-details">
        <div className="testimonailText">
          <div className="top-div-clent-details">
            <span className="client-name">{data?.TravelerName}</span>
          </div>
          <div className="ratting-star-div">
            {ratting.map((data, index) => (
              <img
                key={index}
                className="rattingStar"
                src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/rattingstar.png"
                alt=""
              />
            ))}
          </div>
          <div className="Client-comments">
            {data?.ReviewText.slice(0, 100) + ".."}
          </div>
          <div className="destination-and-ratting">
            <div className="destination-parent-div">
              <img
                className="location-icon"
                src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/location_icon.png"
                alt=""
              />
              <span className="Destination">{data?.Destination}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonialmob;
