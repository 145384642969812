import React from "react";
import "./carreercomponent.css";

const DummyPos = [
  {
    carrer: "Social Media Marketing",
    lable: "On Site",
  },
  {
    carrer: "Social Media Marketing",
    lable: "On Site",
  },
  {
    carrer: "Social Media Marketing",
    lable: "On Site",
  },

  {
    carrer: "Social Media Marketing",
    lable: "On Site",
  },
];

const Openings = () => {
  return (
    <div>
      <h1 className="openinghead">Curently open positions</h1>
      <div className="flex flex-wrap gap-8 openingwrp">
        {DummyPos.map((ele, id) => (
          <div className="openingDiv">
            <div className="openingdivc">
              <h1>{ele.carrer}</h1>
              <p>{ele.lable}</p>
            </div>
          

            <RedirectSvg/>
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default Openings;


const RedirectSvg = () => (
    <svg width="40" height="40" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="34" cy="34" r="25" fill="black"/>
    <path d="M45.0267 26.864L21.3429 44.2411C21.0288 44.4716 20.6361 44.5678 20.2511 44.5087C19.866 44.4495 19.5203 44.2399 19.2898 43.9258C19.0594 43.6117 18.9632 43.219 19.0223 42.834C19.0814 42.449 19.2911 42.1032 19.6052 41.8728L43.289 24.4956C43.6031 24.2652 43.9958 24.1689 44.3809 24.2281C44.7659 24.2872 45.1116 24.4969 45.3421 24.8109C45.5725 25.125 45.6687 25.5177 45.6096 25.9028C45.5505 26.2878 45.3408 26.6335 45.0267 26.864Z" fill="#F8F8F8"/>
    <path d="M43.6683 26.0395L26.642 23.4265C26.2565 23.3673 25.9103 23.1573 25.6796 22.8429C25.4489 22.5284 25.3525 22.1352 25.4117 21.7497C25.471 21.3642 25.6809 21.018 25.9953 20.7872C26.3098 20.5565 26.7031 20.4601 27.0886 20.5194L45.566 23.3574C45.757 23.3865 45.9404 23.4529 46.1056 23.553C46.2709 23.6531 46.4148 23.7848 46.5291 23.9405C46.6434 24.0963 46.7258 24.2731 46.7716 24.4607C46.8175 24.6484 46.8259 24.8433 46.7963 25.0342L43.9583 43.5116C43.8991 43.8971 43.6892 44.2433 43.3747 44.4741C43.0602 44.7048 42.667 44.8012 42.2815 44.7419C41.896 44.6827 41.5498 44.4728 41.3191 44.1583C41.0883 43.8439 40.992 43.4506 41.0512 43.0651L43.6683 26.0395Z" fill="#F8F8F8"/>
    </svg>
    
  );
  
  