import React, { useRef, useState, useEffect } from "react";
import "../NewItinearyPage.css";
import PackageHiglights from "./PackageHiglights";
import Daywise from "../../ItinearyPage/ItinearyHeader/DayWise/Daywise";
import ItinearyCancellationPolicy from "./ItinearyCancellationPolicy";
import InclusionExlusionDetail from "./InclusionExlusionDetail";
import Customise from "../../ItinearyPage/customise/Customise";
import ItenearyFAQ from "./ItenearyFAQ";
import Testimonials from "../../Testimonial/Testimonials";
import ItninearyPackages from "./ItninearyPackages";
import ItinearyBookBtn from "./ItinearyBookBtn";

export const Tab = ({ label, onClick }) => {
  return (
    <button className="tabsSticky" onClick={onClick}>
      {label}
    </button>
  );
};

const ItearnearyMain = () => {
  const sectionRefs = useRef([]);
  const [isVisible, setIsVisible] = useState(false);
  const tabs = [
    { label: "Highlights", component: PackageHiglights },
    { label: "Day wise Itinerary", component: Daywise },
    { label: "Cancellation Policy", component: ItinearyCancellationPolicy },
    { label: "Reviews", component: Testimonials },
  ];

  const handleScrollToSection = (index) => {
    const section = sectionRefs.current[index];

    if (section) {
      const offsetTop = section.offsetTop - 100;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 550) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // function
const [stickyHeight,setStickyHeight]=useState(null);
  useEffect(() => {
    const navbarElement = document.querySelector(".navbar-bottom");

    const resizeObserver = new ResizeObserver(() => {
      if (navbarElement) {
        setStickyHeight(navbarElement.offsetHeight)
      }
    });

    if (navbarElement) {
      resizeObserver.observe(navbarElement);
    }

    return () => {
      if (navbarElement) {
        resizeObserver.unobserve(navbarElement);
      }
    };
  }, []);

  return (
    <div className="px-9 py-4">
      <div className="Itinearytabs1" style={{top:stickyHeight}}>
        <div className="Itinearytabs">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              onClick={() => handleScrollToSection(index)}
            />
          ))}
        </div>
        <div className={isVisible ? "p-visible" : "p-hidden"}>
          <ItinearyBookBtn isSticky={true} />
        </div>
      </div>

      <div className="sections ">
        <div
          className="flex justify-center "
          ref={(el) => (sectionRefs.current[0] = el)}
        >
          <PackageHiglights />
        </div>
        <div ref={(el) => (sectionRefs.current[1] = el)}>
          <Daywise />
        </div>
        <div ref={(el) => (sectionRefs.current[2] = el)}>
          <ItinearyCancellationPolicy />
        </div>
        <InclusionExlusionDetail />
        <Customise />
        <ItenearyFAQ />
        <div ref={(el) => (sectionRefs.current[3] = el)}>
          <Testimonials />
        </div>
        <div className="mt-[8rem] text-[2rem] font-bold">
          <ItninearyPackages />
        </div>
      </div>
    </div>
  );
};

export default ItearnearyMain;
