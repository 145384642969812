import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import snackbarService from "../../Services/snackbarService";
import { BASE_URL } from "../../utils";
import "./Footer.css";

const FooterInput = () => {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState(false); // State to track email input error

  const SubscriptionApi = async (data) => {
    setLoader(true);
    try {
      const res = await axios.post(
        `${BASE_URL}DataTransaction/Subscribers`,
        data
      );

      snackbarService.showSuccess(res?.data?.message);
      setLoader(false);
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { email: email, promotionCode: "SUMMER2024" };
    if (email) {
      setEmailError(false);
      await SubscriptionApi(data);
      setEmail("");
    } else {
      setEmailError(true);
    }
  };

  return (
    <div className="footer-bottom-holder">
      <h3 className="last-title">
        Subscribe to Journey Routers for great travel deals and the latest
        travel news.
      </h3>
      <form
        onSubmit={handleSubmit}
        className="subscribe-parent-div my-2"
        style={{
          border: emailError
            ? "0.0625rem solid #f17165"
            : "0.0625rem solid #FFF",
        }}
      >
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          value={email}
          className="addemial"
          placeholder="Add Email"
        />
        <button type="submit" className="subscribe-button">
          {loader ? <CircularProgress size={24} /> : "  Subscribe"}
        </button>
      </form>
    </div>
  );
};

export default FooterInput;
