import React from "react";
import headerBg from "../../../Assets/CartHeaderBg.png";
import './CartHeader.css'
import NavbarNew from "../../Navbar/NavbarNew";

function CartHeader() {
  return (
    <>
      <div className="Cart_img_container">
        <img src={headerBg} alt="" className="cart_header_bg_img" />
        <NavbarNew />
        <div className="Heading_wraper">
          <div className="Cart_heading">Cart</div>
        </div>
      </div>
    </>
  );
}

export default CartHeader;
