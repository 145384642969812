import React from "react";
import "./carreercomponent.css";

const CarrerSection1 = () => {
  return (
    <div className="flex items-center">
      <div className="sectioncontent flex-1">
        <h1>
        We Foster a Culture of Growth and Success
        </h1>
        <br />
        <div>
          <p className="mb-2 setcionp">
          At Journey Routers, we believe that our success is intertwined with the growth of our people. We nurture talent by providing opportunities to innovate, lead, and make a lasting impact. Together, we drive our business forward, building rewarding careers along the way.
          </p>
        
        </div>
        <div className="sectionbtn">
        See Open Positions
        </div>

      </div>
      <div className="sectionImg flex-1 bg-gray-500">
      <img className="w-full h-full" src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/SupportImageBucket/Career2.jpg" alt=""/>
      </div>
    </div>
  );
};

export default CarrerSection1;
