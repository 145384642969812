import React from 'react'
import useWindowSize from '../../Hooks/useWindowSize'
import "./Gallery.css";
import GalleryDesktop from './GalleryDesktop';
import MobileGallery from './MobileGallery';
import Footer from '../Footer/Footer';
// import Navbar from '../Navbar/navbar';
import NavbarNew from '../Navbar/NavbarNew';

function Gallerypage() {
  const size = useWindowSize();
  return (
    <>
      <NavbarNew isDark={true}/>
      {
        size.width > 768 ? <GalleryDesktop /> : <MobileGallery />
      }
      <Footer />

    </>
  )
}

export default Gallerypage
