import React, { useState } from "react";
import "./QueryCardNew.css";
import { Star } from "lucide-react";
import Loading from "../../Loaders/Loading";
import { Modal } from "@mui/material";
import ItinearyPdf from "../../ItinearyPdf/ItinearyPdf";
import DownloadVoucher from "./DownloadVoucher";
import { Link } from "react-router-dom";

const QueryCardNew = ({ PackageData, loading, index, cardType }) => {
  const [open, setOpen] = useState(false);
  const [hideVoucherDownload, setHideVoucherDownload] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

/* This function is handle for open and close download vocuher fistly this voucher section display is none for not showing animation on component */
  const openCloseDownloadVoucher = () => {
    setHideVoucherDownload(true);
    setIsClicked(true);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const averageRating = (rating) => {
    if (!rating || rating.length === 0) return 0;
    let sum = rating.reduce((acc, num) => acc + num, 0);
    const average = sum / rating.length;
    return average;
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="query_card" key={index}>
          <img
            src={PackageData?.HeroBanners?.Landscape}
            alt="..."
            className="query_cardImage"
          />
          <div className="query_cardContent">
            <h3 className="query_cardTitle">{PackageData?.PackageTitle}</h3>
            <div className="query_cardInfo">
              <span className="query_rating">
                {averageRating(PackageData.HotelRatingList)}{" "}
                <Star fill="#666666" strokeWidth={0} /> Hotels
              </span>
              <span className="query_activities">
                {PackageData?.ActivityList?.length} activities
              </span>
            </div>
            <div className="query_card_cost_type">
              <span>{PackageData?.PriceType} -</span>
              <span className="query_card_price">
                {PackageData.SellingPrice}
              </span>
            </div>
            <div className="query_cardButtons">
              <button className="query_downloadBtn" onClick={handleOpen}>Download PDF</button>
              {
                cardType === 'myBookings' ? 
                <>
                {
                  PackageData.Category === 'Custom' ? 
                  <button className="query_downloadVoucherBtn cursor-not-allowed" >View Details</button> :
                  <Link to={`/ItinearyPage/${PackageData?.DestinationName}/${PackageData?.DestinationId}/${PackageData?.PackageId}`} className="query_downloadVoucherBtn">View Details</Link>

                }
                </>:
              <div className="query_downloadVoucherBtn_parent">
                <button className="query_downloadVoucherBtn" onClick={openCloseDownloadVoucher} style={{width: '100%'}}>
                Download Voucher
                </button>
                <div className={`${isClicked ? (hideVoucherDownload ? 'show_voucher_animation' : 'reverse_voucher_animation') : ''} download_voucher_animation`}>
                  <DownloadVoucher />
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      )}
      {
        hideVoucherDownload && 
      <div className="voucher_download_overlay" onClick={() => setHideVoucherDownload(false)}></div>
      }
        <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "grid",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "1rem",
          with: "100%",
          overflowY: "scroll",
        }}
      >
        <ItinearyPdf DataPassed={PackageData} handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default QueryCardNew;
