import { useState, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "../utils";
import snackbarService from "../Services/snackbarService";
import { useDispatch } from "react-redux";
import { setAuthModel, setUserProfile } from "../features/Profile/profileSlice";
import { useNavigate } from "react-router-dom";

const useAuthHook = () => {
  const [error, setError] = useState(null);
  const [okres, setOkres] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("ClientDetails"))
  );
  const token = localStorage.getItem("UserId_token");

  const logOut = () => {
    localStorage.removeItem("UserId_token");
    localStorage.removeItem("ClientDetails");
    dispatch(setUserProfile(null));
    navigate("/");
  };

  const authApi = async (credentials) => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.post(
        `${BASE_URL}DataTransaction/Authentication`,
        credentials
      );
      setOkres(true);

      localStorage.setItem("UserId_token", response?.data?.UserId);
      snackbarService.showSuccess(response?.data?.message);
      if (response?.data?.UserId && credentials.action === "sign-up") {
        let data = { UserId: response?.data?.UserId };
        await axios.post(`${BASE_URL}DataTransaction/Cart`, data);
      }
      if (
        credentials.action !== "password-reset" ||
        credentials.type === "verify"
      ) {
        getUser(response?.data?.UserId);
      }
    } catch (err) {
      setOkres(false);
      snackbarService.showError(err?.response?.data?.message);
      setError(err.response ? err.response.data.message : "Login failed");
    } finally {
      setLoading(false);
    }
  };

  const getUser = useCallback(async (userId) => {
    try {
      setLoading(true);
      setError(null);
      const cachedUserData = localStorage.getItem("ClientDetails");
      if (cachedUserData) {
        setUser(JSON.parse(cachedUserData));
        dispatch(setUserProfile(JSON.parse(cachedUserData)));
        dispatch(setAuthModel(false));
      } else {
        const response = await axios.get(
          `${BASE_URL}DataTransaction/UserProfile?UserId=${userId}`
        );
        localStorage.setItem("ClientDetails", JSON.stringify(response.data));
        setUser(response.data);
        dispatch(setUserProfile(response.data));
        dispatch(setAuthModel(false));
      }
    } catch (err) {
      setError(
        err.response ? err.response.data.message : "Failed to fetch user data"
      );
    } finally {
      setLoading(false);
    }
  }, []); // Empty dependency array ensures it's only created once

  return {
    user,
    authApi,
    token,
    getUser,
    loading,
    error,
    logOut,
    okres,
  };
};

export default useAuthHook;
