import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    User: null,
    isEditing: false,
    authModelOpenClose: false,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setUserProfile : (state, action) =>{
            state.User = action.payload
        },
        setProfileEditing : (state, action) => {
            state.isEditing = action.payload
        },
        setAuthModel: (state, action) => {
            state.authModelOpenClose = action.payload;
        },
        setUserProfileImage : (state, action) => {
            if (!state.User) {
                state.User = {};
            }
            state.User.ProfileImage = action.payload;
        }
    }
})

export const { setUserProfile, setProfileEditing, setUserProfileImage, setAuthModel } = profileSlice.actions

export default profileSlice.reducer