import React from "react";
import "./Testimonials.css";
function TestimonialsCard({data}) {

  const ratting = [1, 2, 3, 4, 5];
  return (
    <a href={data?.Link} target="blank" className="TestimonialsCardDiv">
      <img
        className="testimonialsCard"
        src={data?.TravelerImage}
        alt=""
      />
      <div className="Testimonials-review-details">
        <div className="top-div-clent-details">
          <span className="client-name">{data?.TravelerName}</span>
          <img
            className="journey-router-logo"
            src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/JourneyRouters.png"
            alt=""
          />
        </div>
        <div className="Client-comments">
       {data?.ReviewText.slice(0,100)+'..'}
        </div>
        <div className="destination-and-ratting">
          <div className="destination-parent-div">
            <img
              className="location-icon"
              src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/location_icon.png"
              alt=""
            />
            <span className="Destination">{data?.Destination}</span>
          </div>
          <div className="ratting-star-div">
            {ratting.map((data, index) => (
              <img
                key={index}
                className="rattingStar"
                src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/rattingstar.png"
                alt=""
              />
            ))}
          </div>
        </div>
      </div>
    </a>
  );
}
export default TestimonialsCard;
