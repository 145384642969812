import { useState } from "react";
import snackbarService from "../Services/snackbarService";
import { useSelector } from "react-redux";
import { BASE_URL } from "../utils";
import useAuthHook from "./useAuthHook";
import axios from "axios";

const useHandleLeadApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const Package = useSelector((state) => state.itninearypackages.data);
  const user = useSelector((state) => state.profile.User);
  const { token } = useAuthHook();

  const LeadApi = async ( ClientName, phoneNumber, Destination, Departure ) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const data = {
      FullName: user?.Name || ClientName,
      Email: user?.Email || "",
      NoOfPax: Package?.Pax || "",
      NoOfChilds: Package?.Childs || "",
      DepartureCity: Departure || "",
      Contact: token || phoneNumber,
      DestinationName: Package?.DestinationName || Destination,
      PackageId: Package?.PackageId,
      Package: Package || "",
    };
    try {
      const res = await axios.post(
        `${BASE_URL}DataTransaction/LeadAnchor`,
        data
    );
    snackbarService.showSuccess(res?.data?.message);
    setSuccess(true)
    navigator.vibrate(100);
    } catch (error) {
        setError(error)
      snackbarService.showError(
        error?.response?.data?.message || "Something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };
  return {
    LeadApi,
    loading,
    success,
    error
  };
};
export default useHandleLeadApi;
