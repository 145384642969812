import React from "react";
import "./Timeline.css";

const Timeline = ({ days, activeDay, setActiveDay }) => {
  return (
    <div className="timeline-container">
      {days.map((day, index) => (
        <div
          key={index}
          className={`timeline-item ${activeDay === day.Day ? "active" : ""}`}
          onClick={() => setActiveDay(day.Day)}
        >
          <div className="bigCircle">
            <div
              className={`circle ${activeDay === day.Day ? "active" : ""}`}
            ></div>
          </div>

          <span
            className={`day-label ${activeDay === day.Day ? "active" : ""}`}
          >
            Day {day.Day}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
