import axios from 'axios';
import { useState, useEffect } from 'react';
import { BASE_URL } from '../utils';

const API_URL = `${BASE_URL}DataTransaction/LeadAnchor`;

const useLeadAnchorData = (contact, type) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch data (GET request)
  const getLeadAnchorData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}?Contact=${contact}&PackageStatus=${type}`);
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Optionally, create a setter function to update data if needed (via POST/PUT)
  const setLeadAnchorData = async (newData) => {
    try {
      const response = await axios.post(API_URL, newData);
      setData(response.data);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    getLeadAnchorData(); // Fetch data when the component mounts
  }, [contact, type]);

  return { data, loading, error, setLeadAnchorData };
};

export default useLeadAnchorData;
