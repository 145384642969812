import React, { useState } from "react";
import "./Login.css";
import loginbg from "../../Assets/loginbg.svg";
import logo from "../../Assets/Logo.png";
import useAuthHook from "../../Hooks/useAuthHook";
import axios from "axios";
import { BASE_URL } from "../../utils";
const SignupForm = ({ onLoginClick }) => {
  const { authApi, okres, token } = useAuthHook();
  const [formValues, setFormValues] = useState({
    Name: "",
    Email: "",
    PhoneNumber: "",
    Password: "",
    Role:'User'
  });
 
  // Handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Handle login button click
  const createAccountClick = async () => {
    const credentials = {
      action: "sign-up",
    };
    await authApi({ ...credentials, ...formValues });
    if(okres && token){
      
      let data = { 'UserId' : token }
      await axios.post(`${BASE_URL}/DataTransaction/Cart`,
        data
      )
    }
  };

  return (
    <div className="LoginWrappermain relative">
      <img className="loginlogo" src={logo} alt="" />
      <div className="LoginMainTitle">Your Next Great Experience.</div>
      <div className="LoginWrapper relative">
        <div className="LoginForm">
          {/* Input fields for Name, Email, Contact, and Departure */}
          <div className="flex  loginformdiv items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="23"
              viewBox="0 0 18 23"
              fill="none"
            >
              <g clipPath="url(#clip0_5703_3708)">
                <path
                  d="M9.00007 11.5003C12.1067 11.5003 14.6251 8.92587 14.6251 5.75015C14.6251 2.57443 12.1067 0 9.00007 0C5.89343 0 3.375 2.57443 3.375 5.75015C3.375 8.92587 5.89343 11.5003 9.00007 11.5003Z"
                  fill="#FE6F61"
                />
                <path
                  d="M9 13.0337C13.9858 13.0337 18 15.2565 18 18.0169V23.0001H0V18.0169C0 15.2559 4.01419 13.0337 9 13.0337Z"
                  fill="#FE6F61"
                />
              </g>
              <defs>
                <clipPath id="clip0_5703_3708">
                  <rect width="18" height="23" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <input
              type="text"
              name="Name"
              value={formValues.Name}
              onChange={handleInputChange}
              placeholder="Name"
            />
          </div>
          <div className="flex loginformdiv items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="18"
              viewBox="0 0 28 18"
              fill="none"
            >
              <g clipPath="url(#clip0_5703_3711)">
                <path
                  d="M13.9748 9.5396L1.87891 0H26.1102L13.9748 9.5396Z"
                  fill="#FE6F61"
                />
                <path
                  d="M9.78279 8.74328L0 16.8978V1.02734L9.78279 8.74328Z"
                  fill="#FE6F61"
                />
                <path
                  d="M11.3628 9.98953L13.9749 12.044L16.7224 9.87988L26.2796 17.9998H1.75488L11.3628 9.98953Z"
                  fill="#FE6F61"
                />
                <path
                  d="M18.3018 8.63931L27.9999 1.02148V16.8746L18.3018 8.63931Z"
                  fill="#FE6F61"
                />
              </g>
              <defs>
                <clipPath id="clip0_5703_3711">
                  <rect width="28" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <input
              type="email"
              name="Email"
              value={formValues.Email}
              onChange={handleInputChange}
              placeholder="Email Id"
            />
          </div>

          <div className="flex loginformdiv items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
            >
              <g clipPath="url(#clip0_5861_3431)">
                <path
                  d="M5.6783 0.7728C5.6783 0.7728 5.34288 0 4.83062 0C4.31835 0 4.06425 0.2292 3.88943 0.3872C3.7146 0.5452 0.789391 2.91 0.789391 2.91C0.789391 2.91 -0.0611367 3.6408 0.00350663 5.016C0.0583925 6.3912 0.331602 8.348 1.7505 11.074C3.15924 13.794 6.68535 17.95 8.9129 19.4108C8.9129 19.4108 10.9774 20.9688 12.898 21.6016C13.4558 21.7748 14.5718 21.9996 14.832 21.9996C15.0922 21.9996 15.5626 21.9996 16.0985 21.6144C16.6433 21.2256 19.6994 18.8076 19.6994 18.8076C19.6994 18.8076 20.4475 18.1424 19.5786 17.3696C18.7058 16.5964 16.055 14.8776 15.4878 14.4256C14.9194 13.9668 14.1108 14.1688 13.7612 14.4796C13.4123 14.7924 12.7895 15.3076 12.713 15.3724C12.5992 15.4584 12.287 15.7372 11.9373 15.5984C11.4921 15.4252 9.66667 14.4504 7.97415 12.1724C6.29221 9.8964 6.10722 9.1524 5.85597 8.3484C5.76815 8.1036 5.86288 7.832 6.08486 7.6916C6.33693 7.5196 7.26511 6.7588 7.26511 6.7588C7.26511 6.7588 7.8656 6.1756 7.61475 5.4888C7.3639 4.802 5.67748 0.7728 5.67748 0.7728H5.6783Z"
                  fill="#FE6F61"
                />
              </g>
              <defs>
                <clipPath id="clip0_5861_3431">
                  <rect width="20" height="22" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <input
              type="number"
              name="PhoneNumber"
              value={formValues.PhoneNumber}
              onChange={handleInputChange}
              placeholder="Contact Number"
            />
          </div>
          <div className="flex loginformdiv items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
            >
              <path
                d="M19.1254 8.74995H16.9587V6.04172C16.9587 2.7511 14.2911 0.0834961 11.0004 0.0834961H9.91721C6.62658 0.0834961 3.95898 2.7511 3.95898 6.04172V8.74995H1.79221C1.19388 8.74995 0.708984 9.23485 0.708984 9.83317V22.8332C0.708984 23.4315 1.19388 23.9164 1.79221 23.9164H19.1254C19.7238 23.9164 20.2087 23.4315 20.2087 22.8332V9.83317C20.2087 9.23485 19.7238 8.74995 19.1254 8.74995ZM6.12543 6.04172C6.12543 3.94775 7.82291 2.24995 9.91721 2.24995H11.0004C13.0944 2.24995 14.7922 3.94742 14.7922 6.04172V8.74995H6.12543V6.04172ZM5.58366 17.9582C4.68633 17.9582 3.95866 17.2305 3.95866 16.3332C3.95866 15.4358 4.68633 14.7082 5.58366 14.7082C6.48098 14.7082 7.20866 15.4358 7.20866 16.3332C7.23921 17.2003 6.56093 17.9276 5.69383 17.9582C5.65711 17.9595 5.62038 17.9595 5.58366 17.9582ZM10.4587 17.9582C9.56133 17.9582 8.83366 17.2305 8.83366 16.3332C8.83366 15.4358 9.56133 14.7082 10.4587 14.7082C11.356 14.7082 12.0837 15.4358 12.0837 16.3332C12.1142 17.2003 11.4359 17.9276 10.5688 17.9582C10.5321 17.9595 10.4954 17.9595 10.4587 17.9582ZM15.3337 17.9582C14.4363 17.9582 13.7087 17.2305 13.7087 16.3332C13.7087 15.4358 14.4363 14.7082 15.3337 14.7082C16.231 14.7082 16.9587 15.4358 16.9587 16.3332C16.9892 17.2003 16.3109 17.9276 15.4438 17.9582C15.4071 17.9595 15.3704 17.9595 15.3337 17.9582Z"
                fill="#FE6F61"
              />
            </svg>
            <input
              type="password"
              name="Password"
              value={formValues.Password}
              onChange={handleInputChange}
              placeholder="Password"
            />
          </div>
        </div>

        <div className="absolute bottom-0">
          <div className="btnwrap">
            <div className="loginBtn2" onClick={createAccountClick}>
              Create Account
            </div>
            <button onClick={onLoginClick}>Login</button>
          </div>

          <img src={loginbg} alt="loginbg" />
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
