import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "../NewItinearyPage.css";
import { ChevronDown } from "lucide-react";
import useApiHandleHook from "../../../Hooks/useApiHandleHook";

export default function ItenearyFAQ() {
  const { getApi, data } = useApiHandleHook();

  React.useEffect(() => {
    getApi(
      "https://2rltmjilx9.execute-api.ap-south-1.amazonaws.com/DataTransaction/FAQ?DestinationName=Global"
    );
  }, [getApi]);


  return (
    <div className=" px-[4rem] pb-8">
      <h1 className="text-[2rem] itninearyFaqhead py-8 font-bold mt-9">
        Frequently Asked Questions (FAQs)
      </h1>
      <div className="bg-[#A9A9A9] w-full h-[2px] mb-8 itninearyFaqhr" />
      <div className="flex gap-8 flex-col">
        {data?.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary
            sx={{
              height: { xs: 50, sm: 'auto' },
              minHeight: {xs: 45, sm: 'auto'},
              '&.Mui-expanded': { minHeight: { xs: 50, sm: 'auto' } }
            }}
              expandIcon={<ChevronDown />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <p className="text-[3rem] md:text-[1.3rem] md:border-b-2 border-b-[1px] border-[#a7a7a7]">{faq.Question}</p>
           
            </AccordionSummary>
            <AccordionDetails>
              <p className="cancellationPolicySubmenus">{faq?.Answer}</p>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
