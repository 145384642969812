// CustomDatePicker.js
import React from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import './CustomDatePicker.css';
import dayjs from 'dayjs';

const CustomDatePicker = ({ label, onDateChange, future = false, past = false, defaultValue }) => (

  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      label={label}
      disableFuture={future}
      disablePast={past}
      onChange={(e) => onDateChange(e.toISOString().split('T')[0])}
      value={defaultValue ? dayjs(defaultValue) : null}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            style: { borderColor: params.error ? 'red' : '#ccc' },
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            style: { color: '#ff5e5e' },
          }}
          className="custom-date-picker"
        />
      )}
    />
  </LocalizationProvider>
);

export default CustomDatePicker;
