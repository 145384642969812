import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../../utils";
import PackagesUniComponent from "../../TravelPackages/PackagesUniComponent";

const ThingsTodoSuggestions = ({ data }) => {
  const {  Destination } = useParams();
  const [cardData, setCardData] = useState([]);

  const fetchSuggestion = async (activityName, destination) => {

    try {
      const response = await axios.get(
        `${BASE_URL}DataTransaction/SearchPackages?Activity=${activityName}&DestinationName=${destination}`
      );
      const fetchedBlogs = response?.data?.data;
      setCardData(fetchedBlogs || []);
    } catch (error) {
      console.error("Failed to fetch suggestions", error);
    }
  };

  useEffect(() => {
    if (data?.sightseeing) {
      fetchSuggestion(data?.sightseeing,data?.destination, );

    }else{

      fetchSuggestion(data.ActivityName, Destination||data?.sightseeing);
    }
    
  }, [data, Destination,data?.sightseeing]); 

  return (
    <>
    {
      cardData.length > 0 &&
      <div>
      <div className="blogsections">
        <h1>Packages</h1>
        <div className="featureOuter">
          <div className="feature-type-main-div">
            {cardData.length > 0 ? (
              cardData.map((ele, index) => (
                <Fragment key={index}>
                  <PackagesUniComponent bundles={ele} />
                </Fragment>
              ))
            ) : (
              <p>No packages found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
    }
    </>
  );
};

export default ThingsTodoSuggestions;
