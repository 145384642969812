import React from "react";
import "./Blogs.css";
const BlogCard = ({data}) => {
  if (!data) {
   return <p>No Data Found!</p> 
  }
  return (
 
      <div className="bloggroup">
        <div className="div">
          <div className="source-times-travel">Source: {data?.Author}</div>
          <div className="sabarimala">{data?.Name}</div>
          <div className="overlap">
            <p className="international">
             {data?.Title}
            </p>
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <div className="text-wrapper">{data?.DestinationName}</div>
              </div>
            </div>
            <div className="overlap-wrapper">
              <div className="overlap-group">
                <div className="text-wrapper-2">Blogs</div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-3">23 June 2023</div>
          <div className="ellipse" />
          <div className="mask-group">


          <img
            className="w-full h-full object-cover"
            alt="Mask group"
            src={data?.ImageURL}
          />
          </div>
        </div>
      </div>
  
  );
};

export default BlogCard;
