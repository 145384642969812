import React from "react";
import locationIcon from "../../Assets/location_black_icon.svg";

import "./BestSelling.css";
import { Link } from "react-router-dom";
const BestSellingCard = ({ Package }) => {
  return (
    <>
      {/* pc responsive */}
      <Link
        to={`/ItinearyPage/${Package.DestinationName}/${Package.DestinationId}/${Package.PackageId}`}
        className="seeling"
      >
        <div className="Rectangle">
          <img
            className="BestSellingCardImg"
            src={Package.HeroBanners.DefaultHeroBanner}
            alt=""
          />
          <div className="Detailsdiv">
            <div>
              <div className="dayscounter  ">
                <span className="daydate">
                  {Package?.Days}D{" "}
                  {Package?.Nights}N
                </span>
                <span className="destinationcard">
                  {" "}
                  <img src={locationIcon} alt="" />
                  {Package.DestinationName}
                </span>
              </div>
              <div className="inclusionList">
                <div className="inclusionListInner">
                  {Package?.InclusionList?.slice(0, 8).map(
                    (data, index) => (
                      <span className="inclusionSpan" key={index}>
                        <img
                          className="greentick"
                          src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/homepage/GreenTick.png"
                          alt=""
                        />
                        {data}
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="sellingCardPrice">
              <div>
                <span className="Price">
                  {new Intl.NumberFormat().format(Package?.SellingPrice)}
                </span>
                <span className="CostType">per person</span>
              </div>
              <button className="ExploreNow">Explore Now</button>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default BestSellingCard;
