import React, { useEffect } from "react";
import "./map.css";
import useApiHandleHook from "../../Hooks/useApiHandleHook";
import { BASE_URL } from "../../utils";
function ItinearyPageMap({ Destination }) {
  const { data, getApi } = useApiHandleHook();

  useEffect(() => {
    getApi(`${BASE_URL}DataTransaction/AllDestinationMap?DestinationName=${Destination}`)
  },[])
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="itnearymap-main">
        <img
          className="Itineary-page-map"
          src={data.MapUrl}
          alt=""
        />
      </div>
    </div>
  );
}

export default ItinearyPageMap;
