import React, { useState } from "react";
import Authenticatiomodal from "./Authenticatiomodal";
import useAuthHook from "../../Hooks/useAuthHook";
import nouserImage from '../../Assets/nouserimage.jpg'  
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthModel } from "../../features/Profile/profileSlice";

const AuthModal = ({ isMobile }) => {
  const user = useSelector((state) => state.profile.User);
  const { logOut } = useAuthHook();
  const dispatch = useDispatch();

  const handleOpen = () => dispatch(setAuthModel(true));

  return (
    <>
      {isMobile ? (
        user ? (
          <div className="flex items-center justify-between sm:gap-8 m-[3rem] gap-[20px]">
            <Link to={"/Profile"}>
              <div className="ProfileAvatar bg-white sm:w-[2rem] w-[40px] sm:h-[2rem] h-[40px] rounded-full">
                <img src={user?.ProfileImage ? user?.ProfileImage : nouserImage} alt="Profile" />
              </div>
            </Link>

            <button
              className="text-white rounded-md bg-red-500 px-6 py-4 text-[3rem]"
              onClick={() => logOut()}
            >
              Logout
            </button>
          </div>
        ) : (
          <div
            onClick={handleOpen}
            className="flex items-center gap-3 m-[3rem]"
          >
            <div className="w-[7.25rem] h-[7.25rem] rounded-full bg-white">
              <svg
                className="ProfileBar"
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
              >
                <path
                  d="M6.50012 0.5C4.71262 0.5 3.25012 2.32 3.25012 4.5625C3.25012 6.805 4.71262 8.625 6.50012 8.625C8.28762 8.625 9.75012 6.805 9.75012 4.5625C9.75012 2.32 8.28762 0.5 6.50012 0.5ZM3.10387 8.625C1.38137 8.70625 0.00012207 10.12 0.00012207 11.875V13.5H13.0001V11.875C13.0001 10.12 11.6351 8.70625 9.89637 8.625C9.01887 9.61625 7.81637 10.25 6.50012 10.25C5.18387 10.25 3.98137 9.61625 3.10387 8.625Z"
                  fill="#485A5E"
                />
              </svg>
            </div>
            <p className="sidebar-links2">Login/Sign Up</p>
          </div>
        )
      ) : (
        <button onClick={handleOpen}>
          <p className="text-white border-[2px] p-2 rounded-lg">Login/SignUp</p>
        </button>
      )}
    </>
  );
};

export default AuthModal;
