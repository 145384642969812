import React from "react";
import "./carreercomponent.css";
const CarreerHeader = () => {
  return (
    <div className="w-full h-[90vh] carrearmain relative">
      {/* <div className="carrerGradient "></div> */}
      <img className="absolute w-full h-full object-cover" src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/SupportImageBucket/Career1.jpg" alt=""/>
      <div className="relative z-[9]">
        <div className="carrpara">
          <h1>CAREERS</h1>
          <p>
          Make an Impact, Shape Your Future
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarreerHeader;
