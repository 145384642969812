import React, { useEffect, useState } from "react";
import { Drawer, List, ListItem, ListItemText, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./SideBar.css";
import sidedrawer from "../../Assets/SideDrawer.svg";
import AuthModal from "../Auth/AuthModal";
import useAuthHook from "../../Hooks/useAuthHook";

const SidebarDrawer = ({
  isOpen,
  toggleDrawer,
  domesticDestinations,
  internationalDestinations,
}) => {
  const { token } = useAuthHook();
  const navigate = useNavigate();
  const [destinationSidebar, setDestinationSidebar] = useState(false);
  const [destinationDomInSidebar, setDestinationDomInSidebar] = useState('');
  const [moreDestinations, setMoreDestinations] = useState(0);
  const [destinationCategory, setDestinationCategory] = useState({
    category: "",
    value: "",
  });
  const sidebarItems = [
    { name: "Home", path: "/" },
    { name: "International", path: "/destination", pageName: "InternationalPage", filterType: 'DestinationName' },
    { name: "Domestic", path: "/destination", pageName: "DomesticPage", filterType: 'DestinationName' },
    {
      name: "Honeymoon Gateways",
      path: "/SearchFilter",
      pageName: "Honeymoon",
      filterType: "Category",
    },
    {
      name: "Family Packages",
      path: "/SearchFilter",
      pageName: "Family",
      filterType: "Category",
    },
    {
      name: "Best Deals",
      path: "/SearchFilter",
      pageName: "BestDeals",
      filterType: "OnSale",
    },
    { name: "About Us", path: "/About" },
    // { name: "career", path: "/Library" },
    { name: "Blog", path: "/blogs" },
    {name:"Carrer",path:"/Career"},
    { name: "Contact Us", path: "/Contact" },
    // { name: "Help", path: "/AboutUs" },
    token && { name: "Profile", path: "/Profile" },
    { name: "Cart", path: "/Cart" },
  ].filter(Boolean);

  const nestedItemClick = (item) => {
    // toggleDrawer(); // Close the drawer when an item is clicked
    setDestinationCategory({
      category: item.filterType,
      value: item.pageName,
    });
    if (!item.filterType) {
      setDestinationSidebar(false);
      navigate(item.path);
      toggleDrawer();
    } else if(item.pageName === 'InternationalPage' || item.pageName === 'DomesticPage') {
      setDestinationDomInSidebar(item.name);
      setDestinationSidebar(false);
    } else{
      setDestinationSidebar(true);
      setMoreDestinations(5);
      setDestinationDomInSidebar('');
    }
  };

  const destinationNavigation = (data) => {
    if(destinationCategory.value === 'InternationalPage' || destinationCategory.value === 'DomesticPage') {
      navigate(`/destination/${data}`)
    }else{
      navigate(
        `/searchResult?DestinationName=${data}&${destinationCategory.category}=${destinationCategory.value}`
      );
    }
    toggleDrawer();
  };

  const showMoreDestinations = (data) => {
    setMoreDestinations(data);
  };
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      if (isOpen) {
        toggleDrawer();
      }
    };
    if (isOpen) {
      window.addEventListener("popstate", handleBackButton);
    }

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpen, toggleDrawer]);
  return (
    <Drawer
      style={{ borderRadius: "15%" }}
      anchor="left"
      open={isOpen}
      onClose={toggleDrawer}
    >
      <div className="sidebar-drawer">
        <div  className="crossbtnWithAuthModel">

        <AuthModal isMobile={true} />
        <div className="crossbtn" onClick={() => toggleDrawer()}>X</div>
        </div>
        <div className="flex">
          <div className="w-[70%]">
            <List>
              {sidebarItems.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem onClick={() => nestedItemClick(item)}>
                    <ListItemText
                      primary={
                        <li
                          className={`sidebar-links ${
                            item.pageName === destinationCategory.value &&
                            "sidebarlinksActive"
                          }`}
                        >
                          {item.name}
                        </li>
                      }
                    />
                  </ListItem>
                  {item.name === "Help" && (
                    <Divider className="white-divider" />
                  )}
                </React.Fragment>
              ))}
            </List>
          </div>
          {destinationSidebar && (
            <div className="w-[30%] destinations_data_drawer">
              <div className="domestic-drawerDestination">
                <h2 className="drawer-destinationname_title">International</h2>
                <ul className="drawer-destinationname_content">
                  {internationalDestinations
                    ?.slice(0, moreDestinations)
                    .map((data, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          destinationNavigation(data.DestinationName)
                        }
                      >
                        {data.DestinationName}
                      </li>
                    ))}
                  {moreDestinations !== domesticDestinations.length - 1 ? (
                    <span
                      onClick={() =>
                        showMoreDestinations(domesticDestinations.length - 1)
                      }
                    >
                      show more
                    </span>
                  ) : (
                    <span onClick={() => showMoreDestinations(5)}>
                      show less
                    </span>
                  )}
                </ul>
              </div>
              <div className="international-drawerDestination">
                <h2 className="drawer-destinationname_title">Domestic</h2>
                <ul className="drawer-destinationname_content">
                  {domesticDestinations
                    ?.slice(0, moreDestinations)
                    .map((data, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          destinationNavigation(data.DestinationName)
                        }
                      >
                        {data.DestinationName}
                      </li>
                    ))}
                  {moreDestinations !== internationalDestinations.length - 1 ? (
                    <span
                      onClick={() =>
                        showMoreDestinations(
                          internationalDestinations.length - 1
                        )
                      }
                    >
                      show more
                    </span>
                  ) : (
                    <span onClick={() => showMoreDestinations(5)}>
                      show less
                    </span>
                  )}
                </ul>
              </div>
            </div>
          )}
          {destinationDomInSidebar && (
            <div className="w-[30%] destinations_data_drawer">
              {
                destinationDomInSidebar === 'International' && 
                <div className="domestic-drawerDestination">
                <h2 className="drawer-destinationname_title">International</h2>
                <ul className="drawer-destinationname_content">
                  {internationalDestinations?.map((data, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          destinationNavigation(data.DestinationName)
                        }
                      >
                        {data.DestinationName}
                      </li>
                    ))}
                </ul>
              </div>
              }
              {
                destinationDomInSidebar === 'Domestic' && 
              <div className="international-drawerDestination">
                <h2 className="drawer-destinationname_title">Domestic</h2>
                <ul className="drawer-destinationname_content">
                  {domesticDestinations?.map((data, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          destinationNavigation(data.DestinationName)
                        }
                      >
                        {data.DestinationName}
                      </li>
                    ))}
                </ul>
              </div>
              }
            </div>
          )}
        </div>
        <div className="absolute w-full object-cover bottom-0">
          <img
            className="w-full h-full object-cover"
            src={sidedrawer}
            alt="img"
          />
        </div>
      </div>
    </Drawer>
  );
};

export default SidebarDrawer;
