import React, { useState, useEffect } from "react";
import axios from "axios";
import "../ProfileNew.css";
import TabHeader from "./TabsLayout/TabHeader";
import TabFooter from "./TabsLayout/TabFooter";
import compressImageToBase64 from "../../../utils/ImageCompressor";
import { useDispatch, useSelector } from "react-redux";
import { setProfileEditing, setUserProfile } from "../../../features/Profile/profileSlice";
import { BASE_URL } from "../../../utils";

const AccountTab = ({ user, updateData }) => {
  // const [isEditing, setIsEditing] = useState(false);
  const isEditing = useSelector((state) => state.profile.isEditing);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    Name: "",
    PhoneNumber: "",
    Email: "",
    DateOfBirth: "",
    Aniversary: "",
    Preferences: {
      Budget: "",
      Accommodation: "",
    },
    ProfileImage: ""
  });

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };
  useEffect(() => {
    if (user) {
      setFormData({
        Name: user?.Name || "",
        PhoneNumber: user?.PhoneNumber || "",
        Email: user?.Email || "",
        DateOfBirth: formatDate(user?.DateOfBirth),
        Aniversary: formatDate(user?.Aniversary),
        Preferences: {
          Budget: user?.Preferences?.Budget || "",
          Accommodation: user?.Preferences?.Accommodation || "",
        },
        UserId: user?.UserId,
        ProfileImage: user?.ProfileImage
      });
    }
  }, [user]);

  const handleEditClick = () => {
    dispatch(setProfileEditing(true));
  };

  const handleImageChange = async(e) => {
    let file = e.target.files[0];
      if(file){
        try {
          const base64String = await compressImageToBase64(file);
          setFormData({ ...formData, ProfileImage: base64String });
        } catch (error) {
          console.error("Image compression failed:", error);
        }
      }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "Budget" || name === "Accommodation") {
      setFormData((prevData) => ({
        ...prevData,
        Preferences: {
          ...prevData.Preferences,
          [name]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSaveClick = async () => {
    dispatch(setProfileEditing(false));

    try {
      const formatToDateObject = (dateStr) =>
        dateStr ? new Date(dateStr) : null;

      const updatedData = {
        ...formData,
        DateOfBirth: formatToDateObject(formData.DateOfBirth),
        Aniversary: formatToDateObject(formData.Aniversary),
      };

      let response = await axios.put(
        `${BASE_URL}DataTransaction/UserProfile`,
        {
          ...updatedData,
          Preferences: {
            Budget: formData?.Preferences?.Budget,
            Accommodation: formData?.Preferences?.Accommodation,
          },
          UserId: user.UserId || formData.PhoneNumber,
        }
      );
      if(response?.status === 200){
        dispatch(setUserProfile(response?.data?.updatedFields))
        setFormData({
          Name: response?.data?.updatedFields?.Name,
          PhoneNumber: response?.data?.updatedFields?.PhoneNumber,
          Email: response?.data?.updatedFields?.Email,
          DateOfBirth: formatDate(response?.data?.updatedFields?.DateOfBirth),
          Aniversary: formatDate(response?.data?.updatedFields?.Aniversary),
          Preferences: {
            Budget: response?.data?.updatedFields?.Preferences?.Budget,
            Accommodation:
              response?.data?.updatedFields?.Preferences?.Accommodation,
          },
          UserId: response?.data?.updatedFields?.UserId,
        });
      }else{
        console.error("Profile not updated")
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <>
      <TabHeader>
        Personal Info{" "}
        <button
          className="absolute right-[5%]"
          onClick={isEditing ? handleSaveClick : handleEditClick}
        >
          {isEditing ? (
            <p className="new_operatableBtn">Save</p>
          ) : (
            <p className="new_operatableBtn">Edit</p>
          )}
        </button>
      </TabHeader>
      <div className="new_AcountTabForm">
        <div className="new_AccFormWrapper">
          <p className="acclable">Name-</p>
          {isEditing ? (
            <input
              className="new_accEditInput"
              type="text"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
            />
          ) : (
            <span>{formData.Name || ""}</span>
          )}
        </div>
        <div className="new_AccFormWrapper">
          <p className="acclable">Phone Number-</p>
          {isEditing ? (
            <input
              type="text" // Changed to text to allow for non-numeric characters
              className="new_accEditInput"
              name="PhoneNumber"
              value={formData.PhoneNumber}
              onChange={handleChange}
            />
          ) : (
            <span>{formData.PhoneNumber || ""}</span>
          )}
        </div>
        <div className="new_AccFormWrapper">
          <p className="acclable">Email-</p>
          {isEditing ? (
            <input
              className="new_accEditInput"
              type="email"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
            />
          ) : (
            <span>{formData.Email || ""}</span>
          )}
        </div>
        <div className="new_AccFormWrapper">
          <p className="dlable">Date of Birth-</p>
          {isEditing ? (
            <input
              type="date"
              className="new_accEditInput"
              name="DateOfBirth"
              value={formData.DateOfBirth}
              onChange={handleChange}
            />
          ) : (
            <span>
              {formData.DateOfBirth
                ? new Date(formData.DateOfBirth).toLocaleDateString("en-US")
                : ""}
            </span>
          )}
        </div>
        <div className="new_AccFormWrapper">
          <p>Aniversary-</p>
          {isEditing ? (
            <input
              type="date"
              className="new_accEditInput"
              name="Aniversary"
              value={formData.Aniversary}
              onChange={handleChange}
            />
          ) : (
            <span>
              {formData.Aniversary
                ? new Date(formData.Aniversary).toLocaleDateString("en-US")
                : ""}
            </span>
          )}
        </div>
        {isEditing && (
          <div className="new_AccFormWrapper">
            <p>Image-</p>
            <input
              type="file"
              className="new_accEditInput"
              name="profilePicture"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
        )}
      </div>
      <TabFooter isAuth={true} />
    </>
  );
};

export default AccountTab;
