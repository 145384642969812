import React, { useEffect, useState } from "react";
import CustomDatePicker from "./CustomeDatePicker";
import bottomSideImg from "../../Assets/adventure.svg";
import useWindowSize from "../../Hooks/useWindowSize";
import mobileBottom from "../../Assets/mobileCustomisebottom.svg";
import axios from "axios";
import snackbarService from "../../Services/snackbarService";
import { BASE_URL } from "../../utils";
import { Modal } from "@mui/material";
import ItinearyPdf from "../ItinearyPdf/ItinearyPdf";
import DaywisePlanUniCompo from "./DaywisePlanUniCompo";

export default function ItinearyDetails({ userData, handleClose }) {
  const {
    fullName,
    contact,
    noOfPax,
    email,
    noOfChilds,
    departureCity,
    destination,
  } = userData;

  const [selectedNights, setSelectedNights] = useState(2);
  const [activities, setActivities] = useState([]);
  const [itineraryData, setItineraryData] = useState([
    {
      ActivityImg: "",
      ActivityName: "",
      Descriptions: "",
      DayTitle: "",
      Day: 1,
      packageHighLightFlag: false,
      Hotel: {
        NearByFacilities: ["Hospitals", "Police Station", "Atm"],
        HotelName: "",
        HotelRatting: "",
        HotelImg: "",
      },
    },
  ]);
  const [ActivitiesList, setActivitiesList] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [itineraryDestination, setItineraryDestination] = useState("");
  const [PackageData, setPackageData] = useState({
    Itineary: Array.from({ length: selectedNights - 1 }, (_, day) => ({
      ActivityImg: "",
      ActivityName: "",
      Descriptions: "",
      DayTitle: "",
      Day: day + 1,
      packageHighLightFlag: false,
      Hotel: {
        HotelImg: "",
        HotelName: "",
        HotelRatting: "",
        NearByFacilities: [],
      },
    })),
    Payments: {
      TotalAmountPaid: 123456,
      PaymentMethod: "",
      PaymentStatus: "",
      PaymentDate: "",
    },
    Cancel: {
      CancellationReason: "",
      RefundAmount: "",
      RefundConditions: "",
    },
    SuitableMonths: [],
    DiscountCode: "",
    CancellationPolicy: "",
    DiscountedAmount: 0,
    PackageId: "",
    DestinationId: null,
    DestinationName: null,
    PackageName: `${contact} ${destination} ${
      selectedNights + 1
    }Days/${selectedNights}Nights`,
    PackageTitle: `${contact} ${destination} ${
      selectedNights + 1
    }Days/${selectedNights}Nights`,
    ExclusionList: ["Visa", "Flight", "Cab"],
    OtherExclusionList: [],
    InclusionList: ["Accommodation", "Sightseeing", "Airport Transfer"],
    OtherInclusionList: [],
    HeroBanners: {
      BestSellingHeroBanner: "",
      Landscape: "url",
      Portait: "url",
    },
    ActivityList: [],
    HospitalRatingList: [],
    Nights: 0,
    Days: 1,
    Pax: 0,
    Child: 0,
    AddedIntoCart: 85,
    CreationDate: new Date(),
    ExpiryDate: "",
    TotalLeadGenerated: "",
    Rating: "4.5",
    ReleaseDate: "",
    UpdatedBy: [
      {
        TimeStamp: "",
        Updater: "",
      },
    ],

    Category: "Custom",
    Type: "",

    BasePrice: 0,
    OnSale: true,
    PercentageOff: 0,
    PriceType: "Custom",
    SellingPrice: 0,
    IsValid: false,
    Status: false,
    TravelDate: selectedDate,
  });

  const [openPdf, setOpenPdf] = React.useState(false);
  const handleClosePDF = () => setOpenPdf(false);

  const [loader, setLoader] = useState(false);

  const handleLeadApi = async () => {
    setLoader(true);
    setOpenPdf(true);
    const data = {
      Contact: contact,
      FullName: fullName,
      Email: email,
      NoOfPax: noOfPax,
      NoOfChilds: noOfChilds,
      DepartureCity: departureCity,
      DestinationName: destination,
      Package: PackageData,
    };
    // handleClose()
    try {
      const res = await axios.post(
        `${BASE_URL}DataTransaction/LeadAnchor`,
        data
      );
      setLoader(false);
      snackbarService.showSuccess(res?.data?.message);
    } catch (error) {
      setLoader(false);
      snackbarService.showError(
        error?.response?.data?.message || "Something went wrong"
      );
    }
  };

  const handleSubmit = async () => {
    if (contact && Object.keys(itineraryData).length >= selectedNights) {
      // setPackageData((prev) => ({
      //   ...prev,
      //   Itineary: [...itineraryData],
      // }));
      await handleLeadApi();
      sessionStorage.removeItem(destination);
    } else {
      snackbarService.showError(
        "Please check contact no. and itinerary details"
      );
    }
  };

  useEffect(() => {
  }, [PackageData]);
  const size = useWindowSize();

  const nights = [
    { night: 2 },
    { night: 3 },
    { night: 4 },
    { night: 5 },
    { night: 6 },
    { night: 7 },
    { night: 8 },
  ];

  const fetchActivity = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}DataTransaction/Destination?destinationname=${destination}&activitylist=${1}`
      );
      const fetchedActivities = response?.data?.ActivityList;
      setActivities(fetchedActivities);
    } catch (error) {
      console.error("Failed to fetch destinations", error);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    sessionStorage.setItem(
      itineraryDestination,
      JSON.stringify({
        itineraryData,
        selectedDate: date,
        selectedNights,
      })
    );
    setPackageData((prevState) => ({
      ...prevState,
      TravelDate: date,
    }));
    // storeItineraryData()
  };
  
  const handleNightChange = (event) => {
    setSelectedNights(parseInt(event.target.value));
    sessionStorage.setItem(
      itineraryDestination,
      JSON.stringify({
        itineraryData,
        selectedDate,
        selectedNights: parseInt(event.target.value),
      })
    );
  };

  const handleFormChange = (event, day, fieldType) => {
    const selectedValue = event.target.value;
    let updatedData;
    let updatedActivitiesList = [...ActivitiesList];
    const selectedActivity = activities.find(
      (activity) => activity.ActivityName === selectedValue
    );
    if (fieldType === "activity" && selectedActivity) {
      if (!ActivitiesList.includes(selectedActivity.ActivityName)) {
        updatedActivitiesList = [...ActivitiesList, selectedActivity.ActivityName];
        setActivitiesList(updatedActivitiesList);
      }
    }
    setItineraryData((prevState) => {
      updatedData = [...prevState];

      if (fieldType === "activity") {
        updatedData[day - 1] = {
          ...prevState[day - 1],
          ActivityImg: selectedActivity.url,
          ActivityName: selectedActivity.ActivityName,
          Day: day,
          DayTitle: "",
          Descriptions: "",
          Hotel: {
            ...prevState[day - 1]?.Hotel, // Spread previous hotel data to keep other properties
            HotelRatting: prevState[day - 1]?.Hotel?.HotelRatting,
          },
        };
      } else if (fieldType === "hotel") {
        updatedData[day - 1] = {
          ...prevState[day - 1],
          Hotel: {
            ...prevState[day]?.Hotel, // Preserve existing hotel data
            HotelRatting: selectedValue, // Merge new hotel data from selectedValue
          },
        };
      }

      // Store updated data in session storage
      sessionStorage.setItem(
        itineraryDestination,
        JSON.stringify({
          itineraryData: updatedData,
          selectedDate,
          selectedNights,
          selectedHotel: updatedData[day]?.Hotel,
        })
      );

      return updatedData;
    });
    setPackageData((prevPackageData) => ({
      ...prevPackageData,
      Itineary: updatedData,
      TravelDate: selectedDate,
      Nights: selectedNights,
      Days: selectedNights + 1,
      ActivityList : updatedActivitiesList
    }));
  };

  const loadItineraryData = () => {
    const userData = sessionStorage.getItem("userFormData");
    if (userData) {
      const userDataParsed = JSON.parse(userData);
      // debugger
      const storedData = sessionStorage.getItem(userDataParsed?.destination);
      setItineraryDestination(userDataParsed?.destination);

      if (storedData) {
        const parsedData = JSON.parse(storedData);
        // setPackageData(parsedData);
        setItineraryData(parsedData.itineraryData);
        setSelectedNights(parsedData.selectedNights);
        setSelectedDate(parsedData.selectedDate);
      }
    }
  };

  useEffect(() => {
    loadItineraryData(); // Load stored itinerary data on component mount
    fetchActivity();
  }, []);
  return (
    <>
      <form className="plan_trip_form itineary_plan">
        <div className="itineary_details">
          <div className="plan_trip_form_group custom_data">
            <CustomDatePicker
              label="Travel Date"
              past={true}
              defaultValue={selectedDate}
              onDateChange={handleDateChange}
            />
          </div>
          <div className="plan_trip_form_group custom_data">
            <select
              id="destinations"
              placeholder=" "
              required
              onChange={handleNightChange}
              value={selectedNights}
            >
              <option value="" disabled>
                Select
              </option>
              {nights.map((item, index) => (
                <option key={index} value={item.night}>
                  {item.night} Nights
                </option>
              ))}
            </select>
            <label htmlFor="destinations">Nights</label>
          </div>
        </div>
        {Array.from({ length: selectedNights + 1 }, (_, index) => (
          <DaywisePlanUniCompo
            key={index}
            day={index + 1} // Assuming day starts from 1
            activities={activities}
            itineraryData={itineraryData}
            handleFormChange={handleFormChange}
          />
        ))}
      </form>
      {size.width < 768 ? (
        <div className="plan_your_trip_bottom_mobile">
          <div className="plan_trip_buttons">
            <div className="button_save" onClick={handleSubmit}>
              Save &amp; Continue
            </div>
          </div>
          <img src={mobileBottom} alt="mobile_bottom_svg" />
        </div>
      ) : (
        <div className="plan_trip_bottom_fix">
          <div className="plan_trip_buttons">
            <div className="button_save" onClick={handleSubmit}>
              Save &amp; Continue
            </div>
          </div>
          <img className="plan_adventure_img" src={bottomSideImg} alt="" />
        </div>
      )}
      <Modal
        open={openPdf}
        onClose={handleClose}
        style={{
          display: "grid",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "1rem",
          with: "100%",
          overflowY: "scroll",
        }}
      >
        <ItinearyPdf DataPassed={PackageData} handleClose={handleClosePDF} />
      </Modal>
    </>
  );
}
