import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "../NewItinearyPage.css";
import { ChevronDown } from "lucide-react";
import { Link } from "react-router-dom";

export default function ItinearyCancellationPolicy() {
  return (
    <div className="pt-[10rem] md:pt-[1.5rem]">
        <Link to='/Support' className="text-[2rem] cancellationHeading">Journey Routers Cancellation Policy</Link>
    </div>
  );
}
