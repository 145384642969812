import React, { useState, useEffect, useRef } from "react";
import "./DayWise.css";
import DaywiseuniCompo from "./DaywiseuniCompo";
import useWindowSize from "../../../../Hooks/useWindowSize";
import DaywiseUniCompoMobile from "./DaywiseUniCompoMobile";
import { useSelector } from "react-redux";
import Timeline from "../../../ItinearyPageTest/itenearyNewComponents/TimeLine";

export function scrollToDiv(day) {
  const targetElement = document.getElementById(`day${day}`);
  if (targetElement) {
    const offsetTop = targetElement.offsetTop - 140; 
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  }
}
function Daywise() {
  const Package = useSelector((state) => state.itninearypackages.data);
  const size = useWindowSize();
  const [activeDay, setActiveDay] = useState(1); 
  const dayRefs = useRef([]); 


  const updateActiveDayOnScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2; 

    dayRefs.current.forEach((dayRef, index) => {
      if (dayRef) {
        const dayOffsetTop = dayRef.offsetTop;
        const dayHeight = dayRef.offsetHeight;

        if (
          scrollPosition >= dayOffsetTop &&
          scrollPosition < dayOffsetTop + dayHeight
        ) {
          setActiveDay(parseInt(dayRef.getAttribute("data-day"), 10));
        }
      }
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      updateActiveDayOnScroll();
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!Package) {
    return null;
  }

  return (
    <div className="Day-wise-centerlise">
      <div className="Day-wise-main">
        <span className="Day-wise">Day Wise</span>
        <div className="Day-wise-individual-day-main">

        <Timeline
          days={Package.Itineary}
          activeDay={activeDay}
          setActiveDay={(day) => {
            setActiveDay(day);
            scrollToDiv(day); 
          }}
        />
        </div>

        <div style={{ marginTop: "5rem" }} className="Day_wiseAll_parent px-4">
          {size.width > 768 ? (
            <>
              {Package?.Itineary.map((data, index) => (
                <div
                  key={index}
                  id={`day${data.Day}`}
                  data-day={data.Day}
                  ref={(el) => (dayRefs.current[index] = el)} 
                >
                  <DaywiseuniCompo
                    data={data}
                    lastCompo={Package?.Itineary.length - 1 === index}
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              {Package?.Itineary.map((data, index) => (
                <div
                  key={index}
                  id={`day${data.Day}`}
                  data-day={data.Day}
                  ref={(el) => (dayRefs.current[index] = el)} 
                >
                  <DaywiseUniCompoMobile
                    data={data}
                    lastCompo={Package?.Itineary.length - 1 === index}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Daywise;
