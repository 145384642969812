import { Modal } from "@mui/material";
import { X } from "lucide-react";
import { default as React, useState } from "react";
import ForgetPassword from "./ForgetPassword";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import { useDispatch, useSelector } from "react-redux";
import { setAuthModel } from "../../features/Profile/profileSlice";
export default function Authenticatiomodal() {
  const [activeForm, setActiveForm] = useState("login");
  const dispatch = useDispatch();
  const opens = useSelector((state) => state.profile.authModelOpenClose);
  const handleClose = () => dispatch(setAuthModel(false));
  const switchForm = (formName) => {
    setActiveForm(formName);
  };
  return (    
      <Modal
        open={opens}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div onClick={handleClose} className="closeModalBtn">
            <X />
          </div>
          {activeForm === "login" && (
            <LoginForm
              onSignupClick={() => switchForm("signup")}
              onForgetPasswordClick={() => switchForm("forgetPassword")}
            />
          )}
          {activeForm === "signup" && (
            <SignupForm onLoginClick={() => switchForm("login")} />
          )}
          {activeForm === "forgetPassword" && (
            <ForgetPassword onLoginClick={() => switchForm("login")} />
          )}
        </>
      </Modal>
  );
}
