import React, { useEffect, useState } from "react";
import useWindowSize from "../../Hooks/useWindowSize";
import ItinearyDesktopPage from "./ItinearyDesktopPage";
import ItinearyMobilePage from "./ItinearyMobilePage";
import { BASE_URL } from "../../utils";
import { setPackageData } from "../../features/itnineary/itninearySlice";
import { useParams } from "react-router-dom";
import useApiHandleHook from "../../Hooks/useApiHandleHook";
import { useDispatch } from "react-redux";
import Loading from "../Loaders/Loading";

const ItinearyMainPages = () => {
  const size = useWindowSize();
  const dispatch = useDispatch();
  let { DestinationId, PackageId } = useParams();
  const { getApi, data, success, loading } = useApiHandleHook();

  useEffect(() => {
    window.scrollTo(0, 0);
    getApi(
      `${BASE_URL}DataTransaction/GetIndividualPackage/?DestinationId=${DestinationId}&PackageId=${PackageId}`
    );
  }, [DestinationId, PackageId, getApi]);

  useEffect(() => {
    if (success) {
      dispatch(setPackageData(data));
    }
  }, [success, data, dispatch]);

  if (size.width > 468) {
    return <>{loading ? <Loading /> :<ItinearyDesktopPage />}</>
  }
  return <>{loading ? <Loading /> : <ItinearyMobilePage />}</>
};

export default ItinearyMainPages;
