

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carreercomponent.css";

const staticMotivation = [
  {
    title: "Innovative Thinkers",
    description:
      "Always looking for new ways to transform travel, our team finds creative solutions to everyday challenges.",
  },
  {
    title: "Customer-Centric Mindset",
    description:
      "Our customers’ happiness is at the heart of everything we do. We obsess over every detail to exceed expectations.",
  },
  {
    title: "Collaborative & Agile",
    description:
      "Teamwork and flexibility fuel our success. We adapt quickly and thrive in fast-paced environments, staying ahead of the curve.",
  },
  {
    title: "Continuous Learners",
    description:
      "We embrace challenges as opportunities to grow. Constant learning helps us sharpen our skills and achieve our shared goals.",
  },
];

const WinningSection = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: true,
  };

  return (
    <div>
      <div className="winningSectionContent">
        <h1 className="winningSectionContentheade">A Winning Combination</h1>

        <div className="flex flex-wrap justify-center">
          {staticMotivation.map((ele, id) => (
            <div className="winingsubpara" key={id}>
              <h1 className="font-bold text-center my-2">{ele.title}</h1>
              <p>{ele.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="winningbg">
        <Slider {...sliderSettings}>
          <div >
            <img
              className="w-full h-full object-cover"
              src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/SupportImageBucket/Career3.jpg"
              alt="Career 1"
            />
          </div>
          <div>
            <img
              className="w-full h-full object-cover"
              src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/SupportImageBucket/Career2.jpg"
              alt="Career 2"
            />
          </div>
          <div>
            <img
              className="w-full h-full object-cover"
              src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/SupportImageBucket/Career1.jpg"
              alt="Career 3"
            />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default WinningSection;
