import React from "react";
import "../NewItinearyPage.css";
import { useDispatch, useSelector } from "react-redux";
import { setDestinationName, setQueryFetcher } from "../../../features/Queryfetcher/queryFetcherModalSlice";

const ItinearyBookBtn = ({ isSticky }) => {
  const dispatch = useDispatch();

  const Package = useSelector((state) => state.itninearypackages.data);
  const handleQueryModalToggle = async (downloadFlg) => {
    // if (token) {
    //   await handleLeadApi({ downloadFlg });
    // } else {
    //   setOpen(!open);
    //   setDownloadPdfCheck(downloadFlg);
    // }
    dispatch(setDestinationName(Package?.DestinationName))
    dispatch(setQueryFetcher(true));
  };

  return (
    <>
      <div className={isSticky ? "herosidebarPrice1" : "herosidebarPrice"}>
        <div className="flex justify-center items-center flex-col w-full">
          {!isSticky && (
            <div className="sidepara">
              <del className="decoration-red-500">
                &#8377; {Package?.BasePrice}
              </del>
            </div>
          )}

          <div>
            <div className="flex flex-row gap-1 items-center">
              <div className=" flex items-center text-white text-[1.4rem]">
                <div className="flex reletive top-[0.5rem] flex-col">
                  <span className="sideparap">Starting from</span>
                  <span className="sideparas">
                    &#8377; {Package?.SellingPrice}
                  </span>
                </div>
                <span className="herosidebarfinalspan">Per Person</span>
              </div>
              <button
                className="herosidebarPriceBtn"
                onClick={handleQueryModalToggle}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItinearyBookBtn;
