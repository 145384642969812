import React from "react";
import "../NewItinearyPage.css";
import { Modal } from "@mui/material";
import PlanYourTrip from "../../PlanTrip/PlanYourTrip";

const ItinerayPlanning = () => {
    const [open, setOpen] = React.useState(false);

  return (
    <>
    <div className="ItinerayPlanningWrapper relative">
      <div className="absolute">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="377"
          height="131"
          viewBox="0 0 377 131"
          fill="none"
        >
          <path
            d="M4.03034 9.21239C-2.08784 25.2812 -4.24719 59.6571 36.0608 68.6098C86.4457 79.8006 41.819 -7.57383 113.438 24.9226C185.056 57.419 185.056 58.0646 205.21 17.3903C225.364 -23.284 210.429 139.198 273.23 103.904C336.031 68.6098 323.435 40.6327 277.549 24.9226C231.662 9.21239 350.607 -10.3715 358.165 9.21239C365.722 28.7963 380.838 123.703 331.712 113.803C282.587 103.904 293.436 5.33863 258.114 30.9484C222.793 56.5581 180.198 78.9398 199.632 103.904C219.066 128.868 148.707 124.994 152.486 89.7001C156.265 54.406 98.3222 32.67 80.6874 54.406C63.0527 76.142 51.5361 130.159 27.7832 99.3845C4.03034 68.6098 135.571 83.4591 118.476 103.904C101.381 124.349 165.622 128.868 176.959 73.1291C188.295 17.3903 258.114 46.8738 258.114 73.1291C258.114 99.3845 265.132 133.172 300.942 129.514C336.751 125.855 316.417 39.9871 348.627 61.0775C380.838 82.1679 390.015 8.23774 336.031 17.3903C292.844 24.7123 344.549 87.7295 375.799 118.323M135.391 9.21239C149.247 52.1105 160.476 133.086 94.5433 113.803C12.1279 89.7001 -2.26776 3.83221 36.0608 17.3903"
            stroke="#E6E4E4"
          />
        </svg>
      </div>

      <h1>Not Sure Where To Travel</h1>
      <p>
        Talk to Travel Experts and get a package based on your personal
        preferences.
      </p>
      <div className="flex gap-4 relative">
        <div className="flex justify-between contactdiv">
          <p>Contact us on:</p>
          <div>
            <p>+9999935176</p>
            <p>+8368045646</p>
          </div>
        </div>
        <div  onClick={()=>setOpen(true)} className="bg-[#F34B4D] px-8 py-4 itnearybtn textwhite font-bold">
          Start Planning
        </div>
      </div>
    </div>
     <Modal
     open={open}
     onClose={() => setOpen(false)}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
   >
     <PlanYourTrip />
   </Modal>
    
    
    </>
  );
};

export default ItinerayPlanning;
