import React from "react";
import "../NewItinearyPage.css";
import { useSelector } from "react-redux";

const PackageMobOverview = () => {
  const data = useSelector((state) => state.itninearypackages.data);
  return (
    <div className="PackageMobOverview">
      <h1>Package Overview</h1>
      <ul>
       {
       data?.Itineary.map((ele,id)=>(
        <li key={id}>
          Day{ele?.Day}-
          <span>
          {ele?.DayTitle}
          </span></li>
       ))
       }
      </ul>
    </div>
  );
};

export default PackageMobOverview;
