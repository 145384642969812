import nouserImage from '../../../../Assets/nouserimage.jpg'
import { useSelector } from "react-redux";

export const AuthHeader = ({ data}) => {
  const user = useSelector((state) => state.profile.User)
    return (
      <div className="new_profileDetails">
        <div className="new_ProfileAvatar w-[4rem] h-[4rem] rounded-full">
          {/* <p>{getInitials(data || 'User')}</p> */}
          <img src={user?.ProfileImage ? user?.ProfileImage : nouserImage} alt="Profile" />
        </div>
        <div className="new_ProfileAvatarName">{data ||'User'}</div>
      </div>
    );
  };