import React from 'react'
import './Blogs.css'
const BlogSearch = () => {
  return (
    <div className="blogsearchWrapper ">
      
        <div className="blogSearch my-8 flex ">
          <input type='text'/>
          <div className="btn">Search</div>
        </div>

    </div>
  )
}

export default BlogSearch