import React from "react";
import useWindowSize from "../../../../Hooks/useWindowSize";
const TabHeader = ({ children }) => {
  const size = useWindowSize();
  return (
    <>
      <div className="new_ProfileAvatarName relative h-[4rem] items-center">{children}</div>
      {size.width > 481 && (
        <div className="w-full h-[1px] mb-[4rem] md:mb-[1rem] bg-gray-400 mt-[1rem]" style={{backgroundColor: '#dcdcdc'}}/>
      )}
    </>
  );
};

export default TabHeader;
