import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Cart.css";
import "../ItinearyPage/PackageHighLights/PackageHIghLights.css";
import CartHeader from "./CartHeader/CartHeader";
import CartProductCard from "./CartProductCard";
import useAuthHook from "../../Hooks/useAuthHook";
import { BASE_URL } from "../../utils";
import Authenticatiomodal from "../Auth/Authenticatiomodal";
import Footer from "../Footer/Footer";
import NoProduct from "./NoProduct";
import useWindowSize from "../../Hooks/useWindowSize";
import BestSellingCard from "../BestSelling/BestSellingCard";
import PortraitCard from "../DestinationPages/DestinationPackages/PortraitCard";
import { Link } from "react-router-dom";

function CartPage() {
  const size = useWindowSize();
  const [cartData, setCartData] = useState(null);
  const [recommendationList, setRecommendationList] = useState([])
  const { token } = useAuthHook();
  const [openAuth, setOpenAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestionloading, setSuggestionLoading] = useState(false);
  const handleCloseAuth = () => setOpenAuth(false);
  const handleOpenAuth = () => setOpenAuth(true);
  const fetchCartData = async () => {
    setLoading(true);
    if (token) {
      try {
        const response = await axios.get(
          `${BASE_URL}DataTransaction/Cart?UserId=${token}`
        );
        const queryString = settingQeryStringForSuggestion(response.data.data.Cart.Packages)
        await recommendationData(queryString);
        setCartData(response.data.data.Cart.Packages);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const queryString = settingQeryStringForSuggestion(cart)
      await recommendationData(queryString);
      setCartData(cart);
    }
  };
  const recommendationData = async (queryStringDestination) => {
      setSuggestionLoading(true)
      try {
        const url = `${BASE_URL}DataTransaction/SearchPackages?DestinationName=${queryStringDestination}&limit=15`;
        const response = await axios.get(url);
        setRecommendationList(response.data.data);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      } finally {
        setSuggestionLoading(false)
      } 
  };

  const settingQeryStringForSuggestion = (data) =>{
    let CartDestinationsSet = new Set();
    data.forEach((item) => {
      CartDestinationsSet.add(item.DestinationName)
    })
    let queryStringDestination = Array.from(CartDestinationsSet).map(destination => destination).join(',');
    return queryStringDestination
  }
  const deleteFromCart = (index) => {
    if (cartData) {
      const updatedCartData = cartData.filter((_, i) => i !== index);
      setCartData(updatedCartData);
    }
  };



  useEffect(() => {
    fetchCartData();
  }, []);

  return (
    <>
      <CartHeader />
      <div className="centralise_cart">
        <div>
          <div className="cart_prod_list">
            <h2 className="cart_product_heading">Product</h2>
            {cartData?.length > 0 ? (
              cartData?.map((pkg, index) => (
                <CartProductCard
                  index={index}
                  handleOpenAuth={handleOpenAuth}
                  Package={pkg}
                  deleteFromCart={deleteFromCart}
                  key={index}
                />
              ))
            ) : (
              <NoProduct />
            )}
          </div>
          { recommendationList.length > 0 &&
            <div className="recommendation_packages">
            <h2>Top recommendations for you</h2>
            <div className="recommendation-Package-outer-div">
              <div className="recommendation-Package-holder">
                {
                  size.width > 768 ?
                    recommendationList?.map((item, i) => (
                      <Link key={i}
                      to={`/ItinearyPage/${item.DestinationName}/${item.DestinationId}/${item.PackageId}`}
                    >
                      <PortraitCard key={i} Package={item} />
                      </Link>
                    )) :
                    recommendationList?.map((item, i) => (
                      <Link key={i}
                      to={`/ItinearyPage/${item.DestinationName}/${item.DestinationId}/${item.PackageId}`}
                    >
                      <BestSellingCard key={i} Package={item} />
                      </Link>
                    ))
                }
              </div>
            </div>
          </div>
          }
        </div>
        <Authenticatiomodal
          opens={openAuth}
          handleClose={handleCloseAuth}
          activeSignUpOrLogin={"signup"}
        />
      </div>
      <Footer />
    </>
  );
}

export default CartPage;
