import React from "react";
import dayWisePlan from "../../../../Assets/black_plan.svg";
import "./DayWiseMobile.css";

const DaywiseUniCompoMobile = ({ data, lastCompo }) => {
  const review = [1, 2, 3, 4, 5];
  var id = `day${data.Day}`;
  // function extractLastPartWithoutExtension(url) {
  //   const parts = url.split("/");
  //   const lastPart = parts[parts.length - 1];
  //   const lastDotIndex = lastPart.lastIndexOf(".");
  //   if (lastDotIndex === -1) return lastPart;
  //   return lastPart.substring(0, lastDotIndex);
  // }
  return (
    <div className="Mobile_Day-wise-DayCard-parent" id={id}>
      <div className="Mobile_Day-wise-DayCard-inner">
        <span className="Mobile_Day-wise-counter">0{data.Day}</span>
        <div className="Mobile_Day-wise-line-and-card">
          {lastCompo ? (
            <div className="Mobile_Day-wise-lastCompo_img_div">
            </div>
          ) : (
            <div className="Mobile_Day-wise-line"></div>
          )}
          <div className="Mobile_Day_wise_activity_parent">
            <div className="Mobile_Day-wise-DayCard_activity_card">
              <div className="Mobile_itineary-details-parent">
                <div className="Mobile_ActivityNameHighLighter">
                  <span className="Mobile_ActivityName">
                    {data.ActivityName}
                  </span>
                </div>

                <div className="Mobile_DayTitle_DayDescription">
                  {data.Descriptions.split(/[\n.]+/).map((data, index) => (
                    <p className="Mobile_DayTitle_description_para" key={index}>
                      {data}
                    </p>
                  ))}
                </div>
              </div>
              <div className="Mobile_day_wise_activityImg">
                <img
                  className="Mobile_Day-wise-DayCard-img-Activity"
                  src={data.ActivityImg}
                  alt=""
                />
                <div className="Mobile_itineary-Activity-details">
                  {review.map((data, index) => (
                    <img
                      key={index}
                      className="Mobile_Day-wise-review"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/rattingstar.png"
                      alt={`Review ${index + 1}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaywiseUniCompoMobile;
