import React, { useEffect, useState } from "react";
import TestimonialsCard from "./TestimonialsCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.css";
import useWindowSize from "../../Hooks/useWindowSize";
import Testimonialmob from "./Testimonialmob";
import { Fragment } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils";
function Testimonials() {
  const [data, setData] = useState();
  const InflunecerApi = async () => {
    try {
      const Res = await axios.get(
        `${BASE_URL}DataTransaction/Reviews?UserId`
      );
      setData(Res.data);
    } catch (error) {
      console.error("Error fetching destinations:", error);
    }
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const size = useWindowSize();
  useEffect(() => {
    InflunecerApi();
  }, []);

  return (
    <div className="centerlise-Testimonials">
      <div>
        <span className="text1">Happy moment gallery</span>
        <br />
        <span className="text2">Some of our happy Journey</span>
      </div>
      <br />
      {/* Conditional Rendring For Between Carousal package and normal Card */}
      {size.width > 768 ? (
        <div className="parent-div-testimonials ">
          {data
            ?.filter((ele) => ele.Status === "true")
            ?.slice(0,3).map((data,id) => (
              <Fragment key={id}>
                <TestimonialsCard data={data} />{" "}
              </Fragment>
            ))}
        </div>
      ) : (
        <Slider {...settings} className="testimonialCarousel">
          {data
            ?.filter((ele) => ele.Status === "true")
            ?.slice(0,3).map((data,id) => (
              <Fragment  key={id}>
                <Testimonialmob data={data} /> {/* Pass _data if necessary */}
              </Fragment>
            ))}
        </Slider>
      )}
    </div>
  );
}

export default Testimonials;
