import React, { useEffect, useState } from 'react'
import useAuthHook from '../../../Hooks/useAuthHook';
import useLeadAnchorData from '../../../Hooks/useLeadApi';
import QueryCardNew from '../QueryPageCard/QueryCardNew';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';

const MyBookingsMobile = () => {
  const { token } = useAuthHook();
  const { data, loading } = useLeadAnchorData(token, '');
  const [activeTab, setActiveTab] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToPrevious = () => {
    navigate(-1)
  }
  useEffect(() =>{
    const path = location.pathname.split("/").pop(); // Get the last part of the path

    if (path) {
      setActiveTab(path);
    }
  },[])
  return (
    <>
    <div className='new_my_bookings_header'>
        <ChevronLeft onClick={navigateToPrevious}/>
        <div className="relative items-center">{activeTab === 'myBookings' ? 'My Bookings' : 'Vouchers'}</div>
        </div>
    <div className="w-full h-[1px] mb-[4rem] md:mb-[1rem] bg-gray-400 mt-[1rem]" style={{backgroundColor: '#dcdcdc'}}/>
    <div className="query__parent_new">
      {
        data?.map((Package, index) =>(
            <QueryCardNew PackageData={Package.Package} key={index} loading={loading} cardType={activeTab}/>
        ))
      }
    </div>
    </>
  )
}

export default MyBookingsMobile