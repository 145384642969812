import React, { useState } from "react";
import "../NewItinearyPage.css";
import {
  renderExclusions,
  renderInclusions,
  renderOthers,
} from "../../../utils/InclusionExlusionUtils";
import { useSelector } from "react-redux";
const InclusionExlusionDetail = () => {
  const Package = useSelector((state) => state.itninearypackages.data);
  return (
    <div className="inclusionExclusionDetail">
      <InclusionCard Package={Package} />
      <ExclusinCard Package={Package} />
    </div>
  );
};

export default InclusionExlusionDetail;

const InclusionCard = ({ Package }) => {
  const [expandIncluion, setExpandInclusion] = useState(false);
  return (
    <div
      data-viewInclusion={expandIncluion ? true : false}
      className="itinearyFeaturediv itinearyFeaturediviNCLUSION book-now-exclusion"
    >
      <h3>Inclusions</h3>
      <div>
        <div className="grid grid-cols-2 gap-2">
          {renderInclusions(Package?.InclusionList)}
        </div>
        <div className="grid grid-cols gap-2">
          {renderOthers(Package?.OtherInclusionList, "inc")}
        </div>
      </div>
    </div>
  );
};
const ExclusinCard = ({ Package }) => {
  return (
    <div
      data-view="tru"
      className="itinearyFeaturediv itinearyFeaturedivEXCLUSION book-now-exclusion"
    >
      <div className="book-now-exclusion">
        <h3>Exclusions</h3>
        <div className="grid grid-cols-2 gap-2">
          {renderExclusions(Package?.ExclusionList)}
        </div>
        <div className="grid grid-cols gap-2">
          {renderOthers(Package?.OtherExclusionList, "exc")}
        </div>
      </div>
    </div>
  );
};
