import React from "react";
import useAuthHook from "../../../../Hooks/useAuthHook";
const TabFooter = ({ isAuth }) => {
  const {logOut}=useAuthHook()
  return (
    <div
      className={`flex  tabfooterIng ${
        isAuth === true
          ? "justify-end  items-start"
          : "justify-between items-end"
      } mx-auto my-0 mt-[34px] w-[40.25rem]`}
    >
      {/* {(!isAuth && size.width > 469) && (
        <div className="flex items-center py-4">
          <img className="w-[2rem]" src={support} alt="" />
          <p className="text-md font-bold">Connect with our team</p>
        </div>
      )} */}
      {isAuth && <div className="new_AuthBtn" onClick={()=>logOut()}>Logout</div>}
      {/* <img src={bakground} className="w-[14rem] profileMobback2" alt="" /> */}
    </div>
  );
};

export default TabFooter;
