import React, { useState } from "react";
import axios from "axios";
import DeleteIcon from "../../Assets/cart_delete_icon.svg";
import { CircularProgress, Modal } from "@mui/material";
import ItinearyPdf from "../ItinearyPdf/ItinearyPdf";
import snackbarService from "../../Services/snackbarService";
import { BASE_URL } from "../../utils";
import useAuthHook from "../../Hooks/useAuthHook";
import QueryFetcherModal from "../Modals/QueryFetcherModal";
import useWindowSize from "../../Hooks/useWindowSize";
// import Authenticatiomodal from "../Auth/Authenticatiomodal";

const CartProductCard = ({
  index,
  Package,
  deleteFromCart,
  handleOpenAuth,
}) => {
  const [openPdf, setOpenPdf] = useState(false);
  const [loader, setLoader] = useState(null);
  const [bookLoader, setBookloader] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(null);
  const [queryModalOpen, setQueryModalOpen] = useState(false);
  const [downloadPdfCheck, setDownloadPdfCheck] = useState(null);
  const size = useWindowSize();

  const handleLeadApi = async ({downloadFlg, ClientName, phoneNumber}) => {
    if (downloadFlg === "download" || downloadPdfCheck === 'download') setLoader(index);
    else setBookloader(index);
    const ApiDataPassing = {
      Name: ClientName, 
      Contact: token || phoneNumber,
      DestinationName: Package.DestinationName,
      PackageId: Package.PackageId,
      Package: Package,
    };

    try {
      const res = await axios.post(
        `${BASE_URL}DataTransaction/LeadAnchor`,
        ApiDataPassing
      );

      // Check if the response status is 200
      if (res.status === 200 && res.data.message) {
        if (downloadFlg === "download" || downloadPdfCheck === 'download') setLoader(true);
        else setBookloader(null);
        if (downloadFlg === "download" || downloadPdfCheck === 'download'){
          handleOpenPdf();
        } else {
          handleDelete(index);
          if(!token) handleOpenAuth();
        }
        setQueryModalOpen(false);
        snackbarService.showSuccess(res?.data?.message);
      } else {
        setLoader(false);
        snackbarService.showError("Failed to delete the item.");
      }
    } catch (error) {
      setLoader(false);
      snackbarService.showError(
        error?.response?.data?.message || "Something went wrong"
      );
    } finally{
      setBookloader(null);
    }
  };

  const handleOpenPdf = () => setOpenPdf(true);
  const handleClosePDF = () => setOpenPdf(false);
  const { token } = useAuthHook();

  const handleDelete = async () => {
    if (token) {
      setDeleteLoader(index);
      const data = {
        UserId: token,
        PackageIndex: index,
      };
      try {
        const response = await axios.delete(`${BASE_URL}DataTransaction/Cart`, {
          data: data, // Send data in the request body
        });
        if (response.status === 200 && response.data.message) {
          deleteFromCart(index);
          snackbarService.showSuccess("Package removed from your cart");
        }
      } catch (error) {
        console.error("Error deleting package from cart:", error);
        snackbarService.showError("Failed to remove package from cart");
      } finally{
        setDeleteLoader(null);
      }
    } else {
      let cart = JSON.parse(localStorage.getItem("cart")) || [];

      // Remove the item at the given index
      cart.splice(index, 1);

      // Update the cart in localStorage
      localStorage.setItem("cart", JSON.stringify(cart));

      snackbarService.showSuccess("Package removed from cart");
      deleteFromCart(index);
    }
  };

  const handleQueryModalToggle = async (downloadFlg) => {
    if(token) {
      await handleLeadApi({downloadFlg});
    }else{
      setDownloadPdfCheck(downloadFlg);
      setQueryModalOpen((prev) => !prev);
    }
  };
  return (
    <div className="cart-product-card">
      <div className="cart_Product_card_inner_sec">
      <div className="cart-product-image">
        <img src={Package?.Itineary[1]?.ActivityImg} alt="" />
      </div>
      <div className="cart-product-info">
        <div className="cart-product-header">
          <span className="cart-package-duration">
            {Package?.Nights}N{" "}
            {Package?.Days}D
          </span>
          <span className="cart-customizable">Customizable</span>
        </div>
        <div className="product_heading_price">
          <div>
            <h2 className="cart-package-name">{Package?.PackageName}</h2>
            {Package?.InclusionList.length > 0 && (
              <ul className="cart-package-features">
                {Package?.InclusionList.slice(0, 2).map(
                  (feature, index) => (
                    <li key={index}>{feature}</li>
                  )
                )}
              </ul>
            )}
          </div>
          <div className="cart-product-price">
            <span className="cart-package-price-text">
              INR {Package?.SellingPrice.toLocaleString()}
            </span>
            <span className="cart-per-person">{Package?.PriceType}</span>
          </div>
        </div>
      {
          size.width > 768 &&
          <div className="cart-product-actions">
          {loader === index ? (
            <button className="cart-book-button">
              <CircularProgress size={20} />
            </button>
          ) : (
            <button
              className="cart-details-button"
              onClick={() => handleQueryModalToggle("download")}
            >
              Download PDF
            </button>
          )}
          {bookLoader === index ? (
            <button className="cart-book-button">
              <CircularProgress size={20} />
            </button>
          ) : (
            <button
              className="cart-book-button"
              onClick={handleQueryModalToggle}
            >
              Book Now
            </button>
          )}
          {deleteLoader === index ? (
            <button className="cart-delete-button">
              <CircularProgress size={20} />
            </button>
          ) : (
            <button className="cart-delete-button" onClick={handleDelete}>
              <img src={DeleteIcon} alt="Delete" />
            </button>
          )}
        </div>
        }
      </div>
      </div>
        {
          !(size.width > 768) &&
          <div className="cart-product-actions">
          {loader === index ? (
            <button className="cart-book-button">
              <CircularProgress size={20} />
            </button>
          ) : (
            <button
              className="cart-details-button"
              onClick={() => handleQueryModalToggle("download")}
            >
              Download PDF
            </button>
          )}
          {bookLoader === index ? (
            <button className="cart-book-button">
              <CircularProgress size={20} />
            </button>
          ) : (
            <button
              className="cart-book-button"
              onClick={handleQueryModalToggle}
            >
              Book Now
            </button>
          )}
          {deleteLoader === index ? (
            <button className="cart-delete-button">
              <CircularProgress size={20} />
            </button>
          ) : (
            <button className="cart-delete-button" onClick={handleDelete}>
              <img src={DeleteIcon} alt="Delete" />
            </button>
          )}
        </div>
        }
        <Modal
        open={queryModalOpen}
        onClose={handleQueryModalToggle}
        className="queryModalSetting"
      >
        <QueryFetcherModal onHandleLeadApi={handleLeadApi}/>
      </Modal>
      <Modal
        open={openPdf}
        onClose={handleClosePDF}
        style={{
          display: "grid",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "1rem",
          with: "100%",
          overflowY: "scroll",
        }}
      >
        <ItinearyPdf DataPassed={Package} handleClose={handleClosePDF} />
      </Modal>
    </div>
  );
};

export default CartProductCard;
