import React, { useState, useEffect, useMemo, useRef } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "./ProfileNew.css";
import useAuthHook from "../../Hooks/useAuthHook";
import {
  AccountTab,
  VoucherTab,
  AccountTabMobile,
  MyBookingsMobile,
} from "./Tabs";
import Navbar from "../Navbar/navbar";
import useWindowSize from "../../Hooks/useWindowSize";
import { AuthHeader } from "./Tabs/TabsLayout/AuthHeader";
import MyBookings from "./Tabs/MyBookings";
import { ChevronRight, PencilLine } from "lucide-react";
import NavbarNew from "../Navbar/NavbarNew";
import nouserImage from "../../Assets/nouserimage.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setProfileEditing, setUserProfileImage } from "../../features/Profile/profileSlice";
import compressImageToBase64 from "../../utils/ImageCompressor";
import { BASE_URL } from "../../utils";
import axios from "axios";

const ProfilePageNew = () => {
  const { token, getUser } = useAuthHook();
  const userProfileImage = useRef(null);
  const [activeTab, setActiveTab] = useState(null);
  const user = useSelector((state) => state.profile.User);
  const navigate = useNavigate();
  const size = useWindowSize();
  const location = useLocation();
  const dispatch = useDispatch();

  const tabs = useMemo(
    () => [
      {
        name: "My Account",
        value: "myAccount",
        path: "myAccount",
        component: size.width < 468 ? AccountTabMobile : AccountTab,
      },
      {
        name: "My Bookings",
        value: "myBookings",
        path: "myBookings",
        component: size.width < 468 ? MyBookingsMobile : MyBookings,
      },
      {
        name: "Vouchers",
        value: "vouchers",
        path: "vouchers",
        component: size.width < 468 ? MyBookingsMobile : VoucherTab,
      },
      { name: "Cart", value: "cart", path: "cart" },
      {
        name: "Terms & Conditions",
        value: "termsConditions",
        path: "termsConditions",
      },
      {
        name: "Help And Support",
        value: "helpSupport",
        path: "helpSupport",
      },
    ],
    []
  );

  const enableEditing = () => {
    dispatch(setProfileEditing(true));
  };

  const redirectWithEditing = () => {
    dispatch(setProfileEditing(true));
    navigate("myAccount");
  };
  const userProfilePicker = () => {
    if (!userProfileImage.current) return;
    else userProfileImage.current.click();
  };

  const profileImageUpdater = async (e) => {
    let file = e.target.files[0];
    if(file){
        const base64String = await compressImageToBase64(file);
        try {
          let response = await axios.put(
            `${BASE_URL}DataTransaction/UserProfile`,
            {
              ProfileImage: base64String,
              UserId: user.UserId || user.PhoneNumber,
            }
          );
          if(response?.status === 200){
            dispatch(setUserProfileImage(response?.data?.updatedFields?.ProfileImage))
          }
        } catch (error) {
          console.error("Api request failed", error);
        }
      }
  }
  useEffect(() => {
    const path = location.pathname.split("/").pop(); // Get the last part of the path
    if (path) {
      setActiveTab(path);
    }

    const handlePopState = () => {
      if (size.width <= 625) {
        setActiveTab(null);
      }
    };

    if (size.width < 469) {
      window.addEventListener("popstate", handlePopState);
    }

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [size.width, location.pathname, tabs]);

  const handleTabClick = (path) => {
    if (size.width <= 468) {
      setActiveTab(path);
    }
    if (path === "cart") navigate("/cart");
    else if (path === "termsConditions") navigate("/Support");
    else if (path === "helpSupport") navigate("/Support");
    else navigate(path);
  };

  // Fething User
  useEffect(() => {
    const userId = token;
    getUser(userId);
  }, [getUser, token]);
  const renderTabs = () => (
    <div className="new_tabButtons new_renderTabsleft">
      <AuthHeader
        data={user?.Name}
        Image={user?.ProfileImage}
        className="new_profilelinksOuter"
      />
      <div className="new_profileDetailsDivider"></div>
      {tabs.map((tab) => {
        const isActive = location.pathname.includes(tab.path);
        return (
          <div
            key={tab.value}
            className={`${
              isActive ? "new_Profileactive" : ""
            } new_profilelinksOuter`}
            onClick={() => handleTabClick(tab.path)}
          >
            <p className="new_profileNavLinks">{tab.name}</p>
          </div>
        );
      })}
    </div>
  );
  const renderTabsMobile = () => (
    <div className="new_profileContainer_mob">
      <div
        className="new_profileImageContainer_mob"
        style={{
          "--background-image": `url(${
            user?.ProfileImage ? user?.ProfileImage : nouserImage
          })`,
        }}
      >
        <div className="user_profile_image_container_mob">
          <img
            src={user?.ProfileImage ? user?.ProfileImage : nouserImage} // Replace with the actual image path
            alt="User Profile"
            className="new_profileImage_mob"
          />
          <div className="new_editProfile_mob">
            <PencilLine className="edit-icon_mob" onClick={userProfilePicker} />
            <input
              type="file"
              ref={userProfileImage}
              name="ProfileImage"
              id="profileImage"
              className="hidden"
              onChange={profileImageUpdater}
            />
          </div>
        </div>
        <h2 className="new_profileName_mob">{user?.Name || "User"}</h2>
      </div>

      <div className="new_tabButtons_mob">
        {tabs.map((tab) => {
          const isActive = location.pathname.includes(tab.path);
          return (
            <div
              key={tab.value}
              className={`${
                isActive ? "new_Profileactive_mob" : ""
              } new_profilelinksOuter_mob`}
              onClick={() => handleTabClick(tab.path)}
            >
              <p className="new_profileNavLinks_mob">{tab.name}</p>
              <ChevronRight className="new_chevron_right_mob" />
            </div>
          );
        })}
      </div>
    </div>
  );
  const renderTabContent = () => (
    <>
      <div
        className={
          activeTab === "myBookings" || activeTab === "vouchers"
            ? "vouchrs_n_bookings"
            : "new_tabContent"
        }
      >
        {size.width <= 469 && (
          <>
            {activeTab === "myBookings" || activeTab === "vouchers" ? (
              <></>
            ) : (
              <div
                className="new_profileImageContainer_mob"
                style={{
                  "--background-image": `url(${
                    user?.ProfileImage ? user?.ProfileImage : nouserImage
                  })`,
                }}
              >
                <div className="user_profile_image_container_mob">
                  <img
                    src={user?.ProfileImage ? user?.ProfileImage : nouserImage} // Replace with the actual image path
                    alt="User Profile"
                    className="new_profileImage_mob"
                  />
                  <div className="new_editProfile_mob">
                    <PencilLine className="edit-icon_mob" onClick={userProfilePicker} />
                    <input
                      type="file"
                      ref={userProfileImage}
                      name="ProfileImage"
                      id="profileImage"
                      className="hidden"
                      onChange={profileImageUpdater}
                    />
                  </div>
                </div>
                <h2 className="new_profileName_mob">{user?.Name || "User"}</h2>
              </div>
            )}
          </>
        )}

        <Routes>
          {tabs.map((tab) => (
            <Route
              key={tab.value}
              path={tab.path}
              element={tab.component && <tab.component user={user} />}
            />
          ))}
          <Route
            path="/"
            element={
              size.width < 468 ? (
                <AccountTabMobile user={user} />
              ) : (
                <AccountTab user={user} />
              )
            }
          />
        </Routes>
      </div>
    </>
  );
  return (
    <div className="new_profilePage_outer">
      <NavbarNew />
      <div className="new_profileCenter">
        <div className="new_profileTabs">
          {size.width < 468 ? (
            activeTab === null || location.pathname === "/Profile" ? (
              renderTabsMobile()
            ) : (
              renderTabContent()
            )
          ) : (
            <>
              {renderTabs()}
              {renderTabContent()}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePageNew;

// Auth Header For Sidebar
