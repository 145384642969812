import React, { forwardRef, useEffect, useState } from "react";
import UserImg from "../../Assets/profile_2.svg";
import locationPin from "../../Assets/SearchFilter/locationPin.svg";

import "./TripPlan.css";
import ItinearyDetails from "./ItinearyDetails";
import { BASE_URL } from "../../utils";
import axios from "axios";

const PlanYourTrip = forwardRef((props, ref) => {
  const { handleClose } = props;

  const [formData, setFormData] = useState({
    fullName: "",
    contact: "",
    noOfPax: "",
    email: "",
    noOfChilds: "",
    departureCity: "",
    destination: "",
  })

  const [formDataError, setFormDataError] = useState({
    fullNameError: false,
    contactError: false,
    noOfPaxError: false,
  });

  const [showPlan, setShowPlan] = useState(false);
  const [destinations, setDestinations] = useState([]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const updatedValue = id === "contact" || id === "noOfPax" || id === "noOfChilds"
      ? value.replace(/[^0-9]/g, "")
      : value;
  
    // Calculate the updated formData
    const newFormData = {
      ...formData,
      [id]: updatedValue,
    };
  
    // Update the formData state
    setFormData(newFormData);
  
    // Update the error state
    setFormDataError((prevErrors) => ({
      ...prevErrors,
      [`${id}Error`]: false,
    }));
  
    // Store the updated formData in sessionStorage
    sessionStorage.setItem("userFormData", JSON.stringify(newFormData));
    // Reset destination if id is not "destination"
    if (id !== "destination") {
      newFormData.destination = "";
      setFormData(newFormData); // Also update the destination reset in the state
    }
  
  };
  

  const handleDestinationChange = (e) => {
    const value = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      destination: value,
    }));

    let hasError = false;

    if (!formData.fullName) {
      setFormDataError((prevErrors) => ({
        ...prevErrors,
        fullNameError: true,
      }));
      hasError = true;
    }

    if (!formData.contact) {
      setFormDataError((prevErrors) => ({
        ...prevErrors,
        contactError: true,
      }));
      hasError = true;
    }

    if (!formData.noOfPax) {
      setFormDataError((prevErrors) => ({
        ...prevErrors,
        noOfPaxError: true,
      }));
      hasError = true;
    }

    if (!hasError && e.target.value) {
    sessionStorage.setItem("userFormData", JSON.stringify({
      ...formData,
      destination : e.target.value
    }));
      setShowPlan(true);
    } else {
      setShowPlan(false);
    }
  };

  const fetchDestinations = async () => {
    try {
      let res = await axios.get(
        `${BASE_URL}DataTransaction/Destination?destinationname=all`
      );
      if (res) {
        const fetchedDestinations = res?.data;
        sessionStorage.setItem(
          "DestinationsLocHome",
          JSON.stringify(fetchedDestinations)
        );
        setDestinations(fetchedDestinations);
      }
    } catch (err) {
      console.error("Unexpected error has been occured", err);
    }
  };

  useEffect(() => {
    const cachedDestinations = sessionStorage.getItem("DestinationsLocHome");
    const cashedUserData = sessionStorage.getItem("userFormData");
    if (cachedDestinations) {
      setDestinations(JSON.parse(cachedDestinations));
    } else {
      fetchDestinations();
    }
    if(cashedUserData) {
      setFormData(JSON.parse(cashedUserData));
      setFormData((prev) =>({
        ...prev,
        destination: ''
      }))
    }
  }, []);

  return (
    <>
      <div className="plan_your_trip_container">
        <h1>Plan Your Travel!</h1>
        <div className="plan_trip_sections">
          <div
            className={
              !showPlan
                ? "plan_trip_usersection usersection_active"
                : "plan_trip_usersection"
            }
            onClick={() => setShowPlan(false)}
          >
            <div className="icon">
              <img src={UserImg} alt="" />
            </div>
            <h2>Personal Details</h2>
          </div>
          <div
            className={
              showPlan
                ? "plan_trip_usersection usersection_active"
                : "plan_trip_usersection"
            }
            onClick={handleDestinationChange}
          >
            <div className="icon">
              <img src={locationPin} alt="" />
            </div>
            <h2>Itinerary</h2>
          </div>
        </div>
        {showPlan ? (
          <ItinearyDetails
            userData={formData}
            handleClose={handleClose}
          />
        ) : (
          <>
            <form className="plan_trip_form">
              <div className="plan_trip_form_group">
                <input
                  type="text"
                  id="fullName"
                  className={formDataError.fullNameError ? "trip_plan_error" : ""}
                  placeholder=""
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
                <label htmlFor="fullName">First Name*</label>
              </div>
              <div className="plan_trip_form_group">
                <input
                  type="text"
                  id="contact"
                  className={formDataError.contactError ? "trip_plan_error" : ""}
                  placeholder=""
                  value={formData.contact}
                  onChange={handleInputChange}
                  maxLength="10"
                />
                <label htmlFor="contact">Contact*</label>
              </div>
              <div className="plan_trip_form_group">
                <input
                  type="text"
                  id="noOfPax"
                  className={formDataError.noOfPaxError ? "trip_plan_error" : ""}
                  placeholder=""
                  value={formData.noOfPax}
                  onChange={handleInputChange}
                />
                <label htmlFor="noOfPax">No. of Pax*</label>
              </div>
              <div className="plan_trip_form_group">
                <input
                  type="email"
                  id="email"
                  placeholder=""
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <label htmlFor="email">Email</label>
              </div>
              <div className="plan_trip_form_group">
                <input
                  type="text"
                  id="noOfChilds"
                  placeholder=""
                  value={formData.noOfChilds}
                  onChange={handleInputChange}
                />
                <label htmlFor="noOfChilds">No. of Children</label>
              </div>
              <div className="plan_trip_form_group">
                <input
                  type="text"
                  id="departureCity"
                  placeholder=""
                  value={formData.departureCity}
                  onChange={handleInputChange}
                />
                <label htmlFor="departureCity">Departure City</label>
              </div>
              <div className="plan_trip_form_group_destination">
                <select
                  id="destinations"
                  placeholder=" "
                  value={formData.destination}
                  onChange={handleDestinationChange}
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {destinations.map((item, index) => (
                    <option value={item.DestinationName} key={index}>
                      {item.DestinationName}
                    </option>
                  ))}
                </select>
                <label htmlFor="destinations">Destination*</label>
              </div>
            </form>
            {/* {size.width < 768 ? (
              <div className="plan_your_trip_bottom_mobile">
                <img src={mobileBottom} alt="" />
              </div>
            ) : (
              <></>
            )} */}
          </>
        )}
      </div>
    </>
  );
});

export default PlanYourTrip;
