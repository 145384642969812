import React from "react";
import CarreerHeader from "./carrercomponents/CarreerHeader";
import NavbarNew from "../Navbar/NavbarNew";
import CarrerSection1 from "./carrercomponents/CarrerSection1";
import WinningSection from "./carrercomponents/WinningSection";
import Openings from "./carrercomponents/Openings";
import Footer from "../Footer/Footer";

const CarrerPage = () => {
  return (
    <>
      <NavbarNew />
      <CarreerHeader />
      <CarrerSection1 />
      <WinningSection />
      <Openings />
      <Footer />
    </>
  );
};

export default CarrerPage;
