import React from "react";
import "../NewItinearyPage.css";

import ItninearyMainContainer from "./ItninearyMainContainer";
import ItninearyBookNowCard from "./ItninearyBookNowCard";
import ItninearyHelpSection from "./ItninearyHelpSection";
import ItinearyBookBtn from "./ItinearyBookBtn";
const ItinearyHero = () => {
  return (
    <div className="px-9 relative flex justify-center">
      <div className="heroUpperLayer">
        <ItninearyMainContainer />
        <div className="heroSidebarWrapper">
          {/* componetstsrts here */}
          <ItninearyBookNowCard />
          {/* endes here */}
          <div className="flex gap-2 flex-col">
            {/* First Component STARTS hERE */}
            <ItninearyHelpSection />
            {/* second componet start here */}
            <ItinearyBookBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItinearyHero;
