import React, { useState } from "react";
import "../NewItinearyPage.css";
import { useSelector } from "react-redux";
import { ChevronDown, ChevronUp, MapPin } from "lucide-react";

const DaywiseMobile = () => {
  const [activeDescription, setActiveDescription] = useState(null);
  const Package = useSelector((state) => state.itninearypackages.data);

  const handleToggleDescription = (id) => {
    if (activeDescription === id) {
      setActiveDescription(null);
    } else {
      setActiveDescription(id);
    }
  };

  return (
    <>
      <div className="text-[5rem] font-bold px-4 pt-[8rem]">
        <h1>DayWise Itineary</h1>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex datywiseMobscroll overflow-x-auto ">
          {Package?.Itineary?.map((ele, id) => (
            <div className="DaywiseMobileCard" key={id}>
              <div className="DaywiseMobileCardimgwrapper relative">
                {/* <img src={ele.ActivityImg} alt="" /> */}
                <figure className="DaywiseMobileCardimgwrapper_img" style={{
                  "--background-image": `url(${ele.ActivityImg})`,
                }}></figure>
              </div>

              <div
                className="DayWiseMobbtmLayer cursor-pointer"
                onClick={() => handleToggleDescription(id)}
                style={{
                  height: activeDescription === id ? "auto" : "40px",
                  overflow: activeDescription === id ? "visible" : "hidden",
                  whiteSpace: activeDescription === id ? "normal" : "nowrap",
                  textOverflow: "ellipsis",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {activeDescription === id ? (
                  <div className="flex items-center px-4 justify-between w-full">
                    <div className="flex gap-2 items-center">
                      <MapPin color="red" size={18} />
                      <p className="text-[3rem]">Day{ele.Day}</p>
                    </div>

                    <div
                      className="text-[3rem] flex items-center gap-3
  text-[gray]"
                    >
                      {ele.ActivityName}
                      <ChevronUp size={18} color="black" />
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center px-4 justify-between w-full">
                    <div className="flex gap-2 items-center">
                      <MapPin color="red" size={18} />
                      <p className="text-[3rem]">Day{ele.Day}</p>
                    </div>

                    <div
                      className="text-[3rem] flex items-center gap-3
    text-[gray]"
                    >
                      {ele.ActivityName}
                      <ChevronDown size={18} color="black" />
                    </div>
                  </div>
                )}
              </div>

              {activeDescription === id && (
                <div className="DaywiseMobileCardDescription z-9 mt-2 p-2 flex">
                  {ele.Descriptions}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DaywiseMobile;
