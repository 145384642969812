import React from 'react'

export default function DaywisePlanUniCompo({day, itineraryData, activities, handleFormChange}) {
  const hotels = [
    { rating: "1 Star" },
    { rating: "2 Star" },
    { rating: "3 Star" },
    { rating: "4 Star" },
    { rating: "5 Star" },
  ];
  return (
    <div className="itineary_details itineary_days_plan" key={day}>
            <div className="itineary_img">
              <h3>Day {day}</h3>
              <img
                src={itineraryData[day - 1]?.ActivityImg}
                alt={itineraryData[day - 1]?.ActivityName}
                width={200}
              />
            </div>
            <div className="itineary_selection">
              <div className="plan_trip_form_group itineary_details_form">
                <select
                  id="activity"
                  onChange={(e) => handleFormChange(e, day, "activity")}
                  required
                  value={itineraryData[day - 1]?.ActivityName}
                >
                  <option value="">
                    Select
                  </option>
                  {activities
                    .filter(item => !item.ActivityName.includes("Title" || "title"))
                    .map((item, index) => (
                      <option value={item.ActivityName} key={index}>
                        {item?.ActivityName}
                      </option>
                    ))}
                </select>
                <label htmlFor="activity">Activity</label>
              </div>
              <div className="plan_trip_form_group itineary_details_form">
                <select
                  id="hotel"
                  onChange={(e) => handleFormChange(e, day, "hotel")}
                  required
                  value={itineraryData[day - 1]?.Hotel?.HotelRatting}
                >
                  <option value="">
                    Select
                  </option>
                  {hotels.map((item, index) => (
                    <option value={item.rating} key={index}>
                      {item.rating}
                    </option>
                  ))}
                </select>
                <label htmlFor="hotel">Hotels</label>
              </div>
            </div>
          </div>
  )
}
