import axios from "axios";
import React, { useEffect, useState } from "react";
import "../ProfileTabsMobile.css";
import {
  CalendarDays,
  CalendarHeart,
  Mail,
  Phone,
  UserRound,
} from "lucide-react";
import useAuthHook from "../../../Hooks/useAuthHook";
import { useDispatch, useSelector } from "react-redux";
import { setProfileEditing } from "../../../features/Profile/profileSlice";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../utils";

const AccountTabMobile = ({ user }) => {
  const isEditing = useSelector((state) => state.profile.isEditing);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Name: "",
    PhoneNumber: "",
    Email: "",
    DateOfBirth: "",
    Aniversary: "",
    Preferences: {
      Budget: "",
      Accommodation: "",
    },
  });
  const {logOut}=useAuthHook()

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };
  useEffect(() => {
    if (user) {

      setFormData({
        Name: user?.Name || "",
        PhoneNumber: user?.PhoneNumber || "",
        Email: user?.Email || "",
        DateOfBirth: formatDate(user?.DateOfBirth),
        Aniversary: formatDate(user?.Aniversary),
        Preferences: {
          Budget: user?.Preferences?.Budget || "",
          Accommodation: user?.Preferences?.Accommodation || "",
        },
        UserId: user?.UserId,
      });
    }
  }, [user]);

  const handleEditClick = () => {
    dispatch(setProfileEditing(true))
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "Budget" || name === "Accommodation") {
      setFormData((prevData) => ({
        ...prevData,
        Preferences: {
          ...prevData.Preferences,
          [name]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSaveClick = async () => {
    dispatch(setProfileEditing(false))
    try {
      const formatToDateObject = (dateStr) =>
        dateStr ? new Date(dateStr) : null;

      const updatedData = {
        ...formData,
        DateOfBirth: formatToDateObject(formData.DateOfBirth),
        Aniversary: formatToDateObject(formData.Aniversary),
      };

      let response = await axios.put(
        `${BASE_URL}DataTransaction/UserProfile`,
        {
          ...updatedData,
          Preferences: {
            Budget: formData?.Preferences?.Budget,
            Accommodation: formData?.Preferences?.Accommodation,
          },
          UserId: user.UserId || formData.PhoneNumber
        }
      );
      if(response.status === 200){
        navigate('/Profile');
        setFormData({
          Name: response?.data?.updatedFields?.Name,
          PhoneNumber: response?.data?.updatedFields?.PhoneNumber,
          Email: response?.data?.updatedFields?.Email,
          DateOfBirth: formatDate(response?.data?.updatedFields?.DateOfBirth),
          Aniversary: formatDate(response?.data?.updatedFields?.Aniversary),
          Preferences: {
            Budget: response?.data?.updatedFields?.Preferences?.Budget,
            Accommodation: response?.data?.updatedFields?.Preferences?.Accommodation,
          },
          UserId: response?.data?.updatedFields?.UserId,
        });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  return (
    <>
      <div className="new_AcountTabForm_mob">
        <div className="new_AccFormWrapper_mob">
          {isEditing ? (
            <div className="new_Input_mob">
              <p className="acclable_mob">Name-</p>
              <input
                type="text"
                name="Name"
                className=""
                value={formData.Name}
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className="new_accEditInput_mob">
              <UserRound fill="#fe6f61" strokeWidth={0} />
              <input
                type="text"
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                readOnly={!isEditing}
              />
            </div>
          )}
        </div>
        <div className="new_AccFormWrapper_mob">
          {isEditing ? (
            <div className="new_Input_mob">
              <p className="acclable_mob">Phone Number-</p>
              <input
                type="text" // Changed to text to allow for non-numeric characters
                name="PhoneNumber"
                value={formData.PhoneNumber}
                onChange={handleChange}
              />
          </div>
          ) : (
            <div className="new_accEditInput_mob">

              <Phone fill="#fe6f61" strokeWidth={0} />
              <input
                type="text" // Changed to text to allow for non-numeric characters
                name="PhoneNumber"
                value={formData.PhoneNumber}
                onChange={handleChange}
                readOnly={!isEditing}
              />
            </div>
          )}
        </div>
        <div className="new_AccFormWrapper_mob">
          {isEditing ? (
            <div className="new_Input_mob">
            <p className="acclable_mob">Email-</p>
            <input
              type="email"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
            />
          </div>
          ) : (
            <div className="new_accEditInput_mob">
              <Mail fill="#fe6f61" strokeWidth={2} color="#fff" />
              <input
                type="email"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                readOnly={!isEditing}
              />
            </div>
          )}
        </div>
        <div className="new_AccFormWrapper_mob">
          {isEditing ? (
            <div className="new_Input_mob">
              <p className="dlable">Date of Birth-</p>
              <input
                type="date"
                name="DateOfBirth"
                value={formData.DateOfBirth}
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className="new_accEditInput_mob">
              <CalendarDays fill="#fe6f61" strokeWidth={2} color="#fff" />
              <input
                type="date"
                name="DateOfBirth"
                value={formData.DateOfBirth}
                onChange={handleChange}
                readOnly={!isEditing}
              />
            </div>
          )}
        </div>
        <div className="new_AccFormWrapper_mob">
          {isEditing ? (
            <div className="new_Input_mob">
              <p>Aniversary-</p>
              <input
                type="date"
                name="Aniversary"
                value={formData.Aniversary}
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className="new_accEditInput_mob">
              <CalendarHeart fill="#fe6f61" strokeWidth={2} color="#fff" />
              <input
                type="date"
                name="Aniversary"
                value={formData.Aniversary}
                onChange={handleChange}
                readOnly={!isEditing}
              />
            </div>
          )}
        </div>
        <div className="button-container">
          <button className="new_acc_actions_button" onClick={()=>logOut()}>Log Out</button>
          {
            isEditing ? 
            <button className="new_acc_actions_button" onClick={handleSaveClick}>Save</button> :
          <button className="new_acc_actions_button" onClick={handleEditClick}>Update</button>
          }
        </div>
      </div>
    </>
  );
};

export default AccountTabMobile;
