import React from "react";
import "./Loading.css";

const Loading = () => {
  return (
    <>
      <div className="loading__parents">
        <div className="loading loading__shimmer">
          <span data-text="J">J</span>
          <span data-text="O">O</span>
          <span data-text="U">U</span>
          <span data-text="R">R</span>
          <span data-text="N">N</span>
          <span data-text="E">E</span>
          <span data-text="Y">Y</span>
          <span data-text=" "> </span>
        </div>
          <div className="loading loading__shimmer2">
            <span data-text="R">R</span>
            <span data-text="O">O</span>
            <span data-text="U">U</span>
            <span data-text="T">T</span>
            <span data-text="E">E</span>
            <span data-text="R">R</span>
            <span data-text="S">S</span>
          </div>
      </div>
    </>
  );
};

export default Loading;
