import React from 'react'
import TabHeader from './TabsLayout/TabHeader'
import TabFooter from './TabsLayout/TabFooter'
import useLeadAnchorData from '../../../Hooks/useLeadApi'
import useAuthHook from '../../../Hooks/useAuthHook'
import QueryCardNew from '../QueryPageCard/QueryCardNew'
import Loading from '../../Loaders/Loading'

const VoucherTab = () => {
  const { token } = useAuthHook();
  const { data, loading } = useLeadAnchorData(token, 'Vouchered');
  return (
    <>
    <TabHeader> Voucher</TabHeader>
    <div className="query__parent_new">
      {
        loading ? <Loading /> :
        data?.map((Package, index) =>(
            <QueryCardNew PackageData={Package.Package} key={index} loading={loading}/>
        ))
      }
    </div>
    <TabFooter></TabFooter>
    </>
  )
}

export default VoucherTab