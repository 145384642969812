import React from "react";
import "./DayWise.css";
import dayWisePlan from "../../../../Assets/black_plan.svg";
function DaywiseuniCompo({ data, lastCompo }) {
  const review = [1, 2, 3, 4, 5];
  var id = `day${data.Day}`;

  return (
    <div id={id} className="Day-wise-DayCard-parent">
      <div className="Day-wise-line-and-card">
        <div>
          {data.Day !== 1 && <div className="Day-wise-line"></div>}

          <span className="Day-wise-counter">0{data.Day}</span>

          {!lastCompo && <div className="Day-wise-line"></div>}
          {lastCompo && (
            <div className="Day-wise-lastCompo_img_div">
              <img
                src={dayWisePlan}
                className="Day-wise-lastCompo_img"
                alt=""
              />
            </div>
          )}
        </div>

        <div className="Day-wise-DayCard_activity_card">
          <img
            className="Day-wise-DayCard-img-Activity"
            src={data.ActivityImg}
            alt=""
          />
          <div className="itineary-details-parent">
            <div className="ActivityNameHighLighter">
              {/* <p>{data.DayTitle}</p> */}
              <span className="ActivityName">{data.DayTitle}</span>
            </div>
            <div className="itineary-Activity-details">
              {review.map((_, index) => (
                <img
                  key={index}
                  className="Day-wise-review"
                  src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/rattingstar.png"
                  alt={`Review ${index + 1}`}
                />
              ))}
            </div>

            <div className="DayTitle_DayDescription">
              {data.Descriptions.split(/[\n.]+/).map((data, index) => (
                <p className="DayTitle_description_para" key={index}>
                  {data}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DaywiseuniCompo;
