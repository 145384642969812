import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Timeline.css";
const ItinearyMobileHeroContainer = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const data = useSelector((state) => state.itninearypackages.data);
  if (!data) {
    return <>loaddd.......</>;
  }
  return (
    <div className="px-8 itineary_mobile_hero">
      <div>
        <h1 className="MbHeroBannerheading">
          {data?.PackageTitle ||
            "Your Journey, Our Expertise: The Ultimate Travel Plan."}{" "}
        </h1>
        <div className="MbHeroBannerh1packName">
          {data?.DestinationName}:
          <span className="MbHeroBannerh1span">
            {data?.Nights}Nights/{data?.Days}Days
          </span>
        </div>
      </div>
      {!data?.Itineary && (
        <div className="mobileHeroBanner gap-2">
          <img data-skeleton="true" src={""} alt={""} />
        </div>
      )}
      <Slider {...settings}>
        {data?.Itineary?.filter(
          (ele) => ele.packageHighLightFlag === true
        )?.map((ele, id) => (
          <div key={id} className="mobileHeroBanner gap-2">
            <img
              data-skeleton="true"
              src={ele?.ActivityImg}
              alt={`Activity ${id}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ItinearyMobileHeroContainer;
