import CrossIconRed from "../../src/Assets/crossIconRed.svg";

function capitalizeWords(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
export const renderInclusions = (list, limit) =>
  list?.slice(0, limit).map((data, index) => (
    <p key={index}>
      <img
        alt="GreenTick"
        className="book-now-green-tick"
        src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/homepage/GreenTick.png"
      />
      <div>{capitalizeWords(data)}</div>
    </p>
  ));

export const renderOthers = (list, type) => {
  const dataList = Array.isArray(list) ? list : list?.split("\n");

  return dataList?.map((data, index) => (
    <p key={index} style={{ width: "100%" }}>
      <img
        alt="GreenTick"
        className={type === "inc" ? "book-now-green-tick" : ""}
        src={
          type === "inc"
            ? "https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/homepage/GreenTick.png"
            : CrossIconRed
        }
      />
      <div className="text-[2.7rem] sm:text-[1.1rem] ">
        {capitalizeWords(data)}
      </div>
    </p>
  ));
};

export const renderExclusions = (list, limit) =>
  list?.slice(0, limit).map((data, index) => (
    <p key={index}>
      <img src={CrossIconRed} alt="cross" width={12} height={12} />
      <span>{capitalizeWords(data)}</span>
    </p>
  ));
