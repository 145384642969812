import React from "react";
import "./AboutUsPage.css"; // Create a separate CSS file for styling
import AboutUsHeader from "./AboutUsHeader";
import visionImg from "../../Assets/Aboutimages/vision.svg";
import targetImg from "../../Assets/Aboutimages/target.svg";
import adaptionImg from "../../Assets/Aboutimages/adaptation.svg";
import collaborateImg from "../../Assets/Aboutimages/collaborate.svg";
import excellentImg from "../../Assets/Aboutimages/excellent.svg";
import customerCentercityImg from "../../Assets/Aboutimages/customer-centricity.svg";
import lowestPriceImg from "../../Assets/Aboutimages/lowest-price.svg";
import BookingImg from "../../Assets/Aboutimages/bookin.svg";
import supportImg from "../../Assets/Aboutimages/24-hours-support-2.svg";
import excitedImg from "../../Assets/Aboutimages/excited.svg";
import eulerLogo from "../../Assets/Aboutimages/eulerLogo.png";
import recruiterLogo from "../../Assets/Aboutimages/recruiterflowlogo.png";
import kayLogo from "../../Assets/Aboutimages/kayLogo.png";
import lionsLogo from "../../Assets/Aboutimages/LionsLogo.png";
import Footer from "../Footer/Footer";

const AboutUsPage = () => {
  return (
    <>
      <AboutUsHeader />
      <div className="about_page_centralise">
        <div className="about-us-container">
          {/* Who we are Section */}
          <div className="who-we-are-section">
            <h3>Who we are?</h3>
            <div className="who-we-are-content">
              <div className="paragraph-left">
                <p>
                  At Journey Routers, we desire to help craft the most
                  adventurous yet intimate vacation you can ever have. We make
                  it our goal to assist you in coming up with a travel
                  experience that specifically meets your standards and
                  expectations for a fun-filled holiday. And when it comes to
                  options, you are guaranteed not to miss out on anything.
                </p>
                <div className="know-more-button">
                  <button>Know More</button>
                </div>
              </div>
              <div className="paragraph-right">
                <p>
                  At Journey Routers, we desire to help craft the most
                  adventurous yet intimate vacation you can ever have. We make
                  it our goal to assist you in coming up with a travel
                  experience that specifically meets your standards and
                  expectations for a fun-filled holiday. And when it comes to
                  options, you are guaranteed not to miss out on anything.
                </p>
              </div>
            </div>
          </div>
          {/* Founder's Message Section */}
          <section className="founder-section">
            <div className="founder-content">
              <h2 className="main-heading-every-section">
                <span className="text-white">Founder's</span> Message
              </h2>
              <p>
                At Journey Routers, we desire to help craft the most adventurous
                yet intimate vacation you can ever have. We make it our goal to
                assist you in coming up with a travel experience that
                specifically meets your standards and expectations for a
                fun-filled holiday.
              </p>
              <p className="founder-name">- Akash Kumar, Founder & CEO</p>
            </div>
            <div className="vision-mission-container">
              <div className="vision-box">
                <img src={visionImg} alt="" />
                <h3>OUR VISION</h3>
                <p>
                  “To be the driving force behind seamless travel solutions for
                  every traveler and organization.”
                </p>
              </div>
              <div className="mission-box">
                <img src={targetImg} alt="" />
                <h3>OUR MISSION</h3>
                <p>
                  “Provide exceptional travel experiences for both B2C and B2B
                  segments.”
                </p>
              </div>
            </div>
          </section>

          {/* Core Values Section */}
          <section className="core-values-section">
            <h2 className="main-heading-every-section">Core Values</h2>
            <div className="values-container">
              <div className="value-box">
                <img src={adaptionImg} alt="" className="sm:hidden"/>
                ADAPTABILITY
                </div>
              <div className="value-box">
                <img src={collaborateImg} alt="" className="sm:hidden"/>
                COLLABORATION
                </div>
              <div className="value-box">
                <img src={excellentImg} alt="" className="sm:hidden"/>
                EXCELLENCE
                </div>
              <div className="value-box">
                <img src={customerCentercityImg} alt="" className="sm:hidden"/>
                CUSTOMER - CENTRICITY
                </div>
            </div>
          </section>

          {/* Why Choose Us section */}
          <section className="why-choose-us">
            <h2 className="main-heading-every-section">Why Choose Us</h2>
            <div className="benefits-container">
              <div className="benefit-box">
                <img src={lowestPriceImg} alt="Lowest Price Icon" />
                <h3>Lowest Price</h3>
                <p>
                  We offer easy and convenient flight bookings with attractive
                  offers.
                </p>
              </div>
              <div className="benefit-box">
                <img src={BookingImg} alt="Easy Bookings Icon" />
                <h3>Easy Bookings</h3>
                <p>
                  We offer easy and convenient flight bookings with attractive
                  offers.
                </p>
              </div>
              <div className="benefit-box">
                <img src={supportImg} alt="24/7 Support Icon" />
                <h3>24/7 Support</h3>
                <p>Get assistance 24/7 on any kind of travel-related query.</p>
              </div>
              <div className="benefit-box">
                <img src={excitedImg} alt="Exciting Deals Icon" />
                <h3>Exciting Deals</h3>
                <p>
                  We offer easy and convenient flight bookings with attractive
                  offers.
                </p>
              </div>
            </div>
          </section>

          {/* our clients section */}
          <section className="our-clients-section">
            <h2 className="main-heading-every-section">Our Clients</h2>
            <div className="our-clients-marque">
              <marquee behavior="" direction="Left">
                <div className="our-clients-image-wrapper">
                  <img src={recruiterLogo} alt="" />
                  <img src={kayLogo} alt="" />
                  <img src={eulerLogo} alt="" />
                  <img src={lionsLogo} alt="" />
                </div>
              </marquee>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUsPage;
