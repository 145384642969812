import React from "react";
import headerImg from "../../Assets/Aboutimages/HeaderImg.png";
import NavbarNew from "../Navbar/NavbarNew";

const AboutUsHeader = () => {
  return (
    <div className="about_img_container">
      <NavbarNew />
      <img src={headerImg} alt="" className="about_header_bg_img" />
      <div className="about_header_content">
        <h1>About Us</h1>
        <p>
          At Journey Routers, we desire to help craft the most adventurous yet
          intimate vacation youcan ever have . We make it our goal to assist you
          in coming up with a travel experience that specifically meets your
          standards and expectations for a fun- filled h
        </p>
        <div className="about-us-buttons">
          <button className="about-button">About Us</button>
          <button className="contact-button">Contact Us</button>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHeader;
