import React from "react";
import "../NewItinearyPage.css";

const ItninearyHelpSection = () => {
  return (
    <div className="herosidebarPrice2">
      <div className="herosidebarPricetext2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="79"
          height="107"
          viewBox="0 0 79 107"
          fill="none"
        >
          <path
            d="M16.2461 94.7394V89.4117C16.2461 80.8204 21.0733 73.9479 27.4246 73.9479C35.0448 82.5391 42.6656 82.5391 50.2869 73.9479C56.6381 73.9479 61.9723 80.8204 61.9723 89.4117V94.7394M46.7302 44.9086C46.7302 46.7989 45.5864 48.3459 44.1907 48.3459C42.795 48.3459 41.6495 46.7989 41.6495 44.9086C41.6495 43.0182 42.7934 41.4712 44.1907 41.4712C45.588 41.4712 46.7302 43.0182 46.7302 44.9086ZM36.5705 44.9086C36.5705 46.7989 35.4266 48.3459 34.0293 48.3459C32.632 48.3459 31.4882 46.7989 31.4882 44.9086C31.4882 43.0182 32.632 41.4712 34.0293 41.4712C35.4266 41.4712 36.5705 43.0182 36.5705 44.9086Z"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.1367 46.6275C25.1367 60.3726 31.2345 68.7921 38.8547 68.7921C46.4749 68.7921 53.0813 60.3726 53.0813 46.6275C53.0813 39.7528 51.8107 36.3176 51.8107 36.3176L46.7301 29.4429C46.7301 29.4429 31.4896 32.8802 26.4073 36.3176C26.4073 36.3176 25.1367 39.7528 25.1367 46.6275Z"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.0449 57.2808C37.4577 58.9972 40.2523 58.9972 42.6651 57.2808"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.7868 70.6823C13.7061 60.3724 16.4991 56.42 18.9135 48.346C20.9461 41.128 18.6601 37.1779 20.5643 27.7262C22.4702 18.2745 29.8386 12.2603 37.9657 12.2603C44.8239 12.2603 48.1271 17.4163 48.1271 17.4163C53.4612 18.1029 57.5264 24.1172 57.5264 31.1614C58.035 43.5332 58.797 43.5332 61.8451 53.1565C64.1311 60.2007 63.1157 64.8418 59.4323 70.5106"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M59.4311 70.5107C58.1605 72.3075 57.1237 74.151 56.0637 75.99M18.7873 70.6824C20.2948 73.48 20.8791 74.2914 21.8485 75.9321M16.2461 94.7396H61.9723"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="herosidebarPricetext2">
        <h1>Need Help?</h1>
        <p>Call us: 9126972980</p>
        <p>Mail us: Journeyrouters@gmail.com</p>
      </div>
    </div>
  );
};

export default ItninearyHelpSection;
