import React from "react";
import "./PortraitCard.css";
import { Link } from "react-router-dom";
function PortraitCard({ Package }) {
  return (
    <Link
      to={`/ItinearyPage/${Package.DestinationName}/${Package.DestinationId}/${Package.PackageId}`}
    >
      <div className="Portrait-card-parent">
        <img
          className="Portrait-card-Img"
          src={Package.HeroBanners.Portait}
          alt=""
        />
        <div className="Portrait-card-details">
          <div className="Portrait-card-details-row1">
            <div className="Portrait-card-cart-days-div">
              <span>{Package.Nights}N</span> <span>{Package.Days}D</span>
            </div>
          </div>
        </div>
        <div className="Portrait_card_bottom">
          <div className="Portrait-card-details-row3">
            {Package.PackageName}
          </div>
          <div className="Portrait-card-details-row5">
            <div>
              <span className="Portrait-card-details-price">
                INR {Package?.SellingPrice}
              </span>
              <span className="Portrait-card-details-per-person">
                {Package.PriceType}
              </span>
            </div>
          </div>
          <div className="Portrait_Card_Inclusions">
            <div className="Portrait_Card_Inclusions_inner">
              {Package.InclusionList.map((item, index) => (
                <div className="Portrait_Card_text_img" key={index}>
                  <img
                    alt=""
                    className="portrait_card_green_tick"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/homepage/GreenTick.png"
                  />
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default PortraitCard;
