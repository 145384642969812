import React from 'react'
import TabHeader from './TabsLayout/TabHeader'
import TabFooter from './TabsLayout/TabFooter'
import useAuthHook from '../../../Hooks/useAuthHook'
import useLeadAnchorData from '../../../Hooks/useLeadApi'
import QueryCardNew from '../QueryPageCard/QueryCardNew'

const MyBookings = () => {
  const { token } = useAuthHook();
  const { data, loading } = useLeadAnchorData(token, '');
  return (
    <>
    
    <TabHeader>My Bookings</TabHeader>
    <div className="query__parent_new">
      {
        data?.map((Package, index) =>(
            <QueryCardNew PackageData={Package.Package} key={index} loading={loading} cardType='myBookings'/>
        ))
      }
    </div>
    <TabFooter></TabFooter>
    </>
  )
}

export default MyBookings