import React, { useEffect } from "react";
import "./Customise.css";
import ItinearyPageMap from "../../Map/ItinearyPageMap";
import useWindowSize from "../../../Hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { setDestinationName, setQueryFetcher } from "../../../features/Queryfetcher/queryFetcherModalSlice";
function Customise({ Destination }) {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const openQueryFetcher = () => {
    dispatch(setDestinationName(Destination));
    dispatch(setQueryFetcher(true));
  }
  return (
  <div style={{display:'flex',justifyContent:'center'}}>

   
        {size.width >= 600 ? (
          <></>
        ) : (
          <ItinearyPageMap Destination={Destination} />
        )}
        <div className="cus-customise-div">
          <img
            className="cus-jr-logo"
            src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/JourneyRoutersFooter.png"
            alt="Journey Routers"
          />
          <h3 className="cus-jr-header">
            Take charge of your journey -Plan Your Travel !
          </h3>
          <h3 className="cus-jr-heade2">
            At Journey Routers, we desire to help craft the most adventurous yet
            intimate vacation you can ever have .
          </h3>
          <div className="cus-logo-parent-div">
            <button className="cus-logo-div-button" 
            onClick={() => openQueryFetcher()}>Customise</button>
            <img
              className="cus-logo-img"
              src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/PackageImage/Customise.png"
              alt="Journey Routers"
            />
          </div>
        </div>
      </div>
 
  );
}

export default Customise;
