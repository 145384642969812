import React from 'react';
import './TravelPackages.css'
import { Link } from 'react-router-dom';
function PackagesUniComponent({bundles, index}) {
    return (
        <Link to={`/ItinearyPage/${bundles?.DestinationName}/${bundles.DestinationId}/${bundles?.PackageId}`} className='Link' key={index}>
            <div className='relative'>
                <img className='packageunicomponent-backgroundImg' src={bundles?.HeroBanners?.Landscape} alt=''/>
                <div className='packageunicomponent-details' >
                    <span className='packageunicomponent-details-fonts'>{bundles?.PackageName.slice(0,18)}</span><br />
                    <span className='packageunicomponent-details-fonts'>{bundles?.Days}D {bundles?.Nights}N</span>
                </div>
            </div>
        </Link>
    );
}

export default PackagesUniComponent;