import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import useApiHandleHook from "../../../Hooks/useApiHandleHook";
import { BASE_URL } from "../../../utils";
import PortraitCard from "../../DestinationPages/DestinationPackages/PortraitCard";

const ItninearyPackages = () => {
  let { Destination } = useParams();

  const { getApi, data } = useApiHandleHook();

  const API = `${BASE_URL}DataTransaction/SearchPackages?DestinationName=${Destination}`;

  useEffect(() => {
    getApi(API);
  }, [API, getApi]);

  return (
    <div className="my-8">
      <h1>{Destination} Packages</h1>
      <div className="flex flex-wrap justify-center gap-4 mt-4">
        {data?.data?.slice(0, 8).map((ele, id) => (
          <Fragment key={id}>
            <PortraitCard Package={ele} />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default ItninearyPackages;
