import axios from "axios";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";

const useApiHandleHook = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const getApi = useMemo(
    () => async (Url, type) => {
      setLoading(true);
      setError(null);

      try {
        const cachedDestinations = localStorage.getItem(
          "DestinationsLocHome"
        );
        if (cachedDestinations && type === 'getAllDestination') {
          setData(JSON.parse(cachedDestinations));
          setSuccess(true);
        } else {
          const Res = await axios.get(Url);
          setData(Res.data);
          setSuccess(true);
        }
      } catch (error) {
        setError(error.message || "An error occurred while fetching data.");
        console.error("Error fetching destinations:", error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const UpdateApi = async (url, payload, msg) => {
    setError(null);
    setLoading(true);
    setSuccess(false);

    try {
      await axios.put(url, payload);

      setSuccess(true);
      toast.success(msg);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setError(error.message || "An error occurred while updating data.");
      console.error("Error updating destinations:", error);
    } finally {
      setLoading(false);
    }
  };

  const PostApi = async (url, payload, msg) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await axios.post(url, payload);
      toast.success(msg);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.error || "An error occurred while Added Influencer."
      );
      console.error("Error Posting destinations:", error);
    } finally {
      setLoading(false);
    }
  };

  const DeleteApi = async (url, payload, msg) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await axios.delete(url, { data: payload });
      toast.success(msg);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.error || "An error occurred while Added Influencer."
      );
      console.error("Error Posting destinations:", error);
    } finally {
      setLoading(false);
    }
  };
  return {
    getApi,
    data,
    loading,
    PostApi,
    error,
    success,
    DeleteApi,
    UpdateApi,
  };
};

export default useApiHandleHook;
