import React from "react";
import "./Footer.css";
import { useState } from "react";
import useWindowSize from "../../Hooks/useWindowSize";
import DropDown from '../../Assets/SuuportImg/dropDown.svg';
import { Link, useNavigate } from "react-router-dom";
import FooterInput from "./FooterInput";
function Footer() {
  const [showDetail, setShowDetail] = useState(0);
  const size = useWindowSize();
  const navigate = useNavigate();
  function handleDetailsClick(inx) {
    if (showDetail === inx) {
      setShowDetail(0);
    } else {
      setShowDetail(inx);
    }
  }
  function handleRedirection (type, query) {
    navigate(`/searchResult?${type}=${query}`)
  }
  function handleRedirectionWithDest ( query1, query2) {
    navigate(`/searchResult?Category=${query1}&DestinationName=${query2}`)
  }
  return (
    <div className="centerlise-Footer">
      <div className="detailsFooter">
        <div className="deatilsInner">
          <img
            className="JRLOGO"
            src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/JourneyRoutersFooter.png"
            alt="JR Logo"
          />
          {size.width <= 768 ? (
        <div className="subscribe-holder">
          <h3 className="email-title">
            Subscribe to Journey Routers for great travel deals and the latest
            travel news.
          </h3>
          <div className="subscribe-parent-div">
            <input type='email' className="addemial" placeholder="Add Email"/>
            <button className="subscribe-button">Subscribe</button>
          </div>
        </div>
      ) : (
        <></>
      )}
          <div className="footer-paralel-div">
            <div>
              <h2 onClick={() => handleDetailsClick(1)} className={
                  showDetail === 1
                    ? "activeHeader"
                    : ""
                }>Packages Type <img src={DropDown} alt="dropdown"/></h2>
              <div
                style={{ width: "18rem" }}
                className={
                  showDetail === 1
                    ? "active footerDetailsView"
                    : "footerDetailsView"
                }
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Category','honeymoon')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Haneymoon"
                  />
                  <span className="footer-packagetypetext">
                    Honeymoon Packages
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Category','Adventure')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Adventure"
                  />
                  <span className="footer-packagetypetext">
                    Adventure Packages
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Category','Family')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Family"
                  />
                  <span className="footer-packagetypetext">
                    Family Packages
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Category','Sea and Beach')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Sea and Beach"
                  />
                  <span className="footer-packagetypetext">
                    Sea and Beach Packages
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('OnSellFlg','WalletFriendly')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Wallet Friendly"
                  />
                  <span className="footer-packagetypetext">
                    Wallet Friendly Packages
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Category','MostPopular')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Most Popular"
                  />
                  <span className="footer-packagetypetext">
                    Most Popular Packages
                  </span>
                </div>
              </div>
            </div>
            <div>
              <h2 onClick={() => handleDetailsClick(2)} className={
                  showDetail === 2
                    ? "activeHeader"
                    : ""
                }>Honeymoon  <img src={DropDown} alt="dropdown"/></h2>
              <div
                style={{ width: "9rem" }}
                className={
                  showDetail === 2
                    ? "active footerDetailsView"
                    : "footerDetailsView"
                }
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirectionWithDest('Honeymoon','Maldives')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Madives"
                  />
                  <span className="footer-packagetypetext">Maldives</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirectionWithDest('Honeymoon','Mauritius')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Mauritius"
                  />
                  <span className="footer-packagetypetext"> Mauritius</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirectionWithDest('Honeymoon','Singapore')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Singapore"
                  />
                  <span className="footer-packagetypetext">Singapore</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirectionWithDest('Honeymoon','Dubai')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Dubai"
                  />
                  <span className="footer-packagetypetext">Dubai</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirectionWithDest('Honeymoon','Thailand')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Thailand"
                  />
                  <span className="footer-packagetypetext">Thailand</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirectionWithDest('Honeymoon','Bali')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="Bali"
                  />
                  <span className="footer-packagetypetext">Bali</span>
                </div>
              </div>
            </div>
            {/* <div>
              <h2 onClick={() => handleDetailsClick(3)} className={
                  showDetail === 3
                    ? "activeHeader"
                    : ""
                }>Wallet-friendly  <img src={DropDown} alt="dropdown"/></h2>
              <div
                style={{ width: "11rem" }}
                className={
                  showDetail === 3
                    ? "active footerDetailsView"
                    : "footerDetailsView"
                }
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Price','40000-50000')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="40k-50k"
                  />
                  <span className="footer-packagetypetext">40,000-50,000</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Price','50000-60000')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="50k-60k"
                  />
                  <span className="footer-packagetypetext">50,000-60,000</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Price','60000-70000')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="60k-70k"
                  />
                  <span className="footer-packagetypetext">60,000-70,000</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Price','70000-80000')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="70k-80k"
                  />
                  <span className="footer-packagetypetext">70,000-80,000</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Price','80000-90000')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="80k-90k"
                  />
                  <span className="footer-packagetypetext">80,000-90,000</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                  onClick={() => handleRedirection('Price','90000-100000')}
                >
                  <img
                    className="footer-location-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                    alt="90k-1lac"
                  />
                  <span className="footer-packagetypetext">90,000-1,00,000</span>
                </div>
              </div>
            </div> */}
            <div>
              <h2 onClick={() => handleDetailsClick(4)} className={
                  showDetail === 4
                    ? "activeHeader"
                    : ""
                }>Seasonally suitable  <img src={DropDown} alt="dropdown"/></h2>
              <div
                style={{ width: "15rem" }}
                className={
                  showDetail === 4
                    ? "active footerSeasonally"
                    : "footerSeasonally"
                }
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','January')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="January"
                    />
                    <span className="footer-packagetypetext">January</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','Feburary')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="Feburary"
                    />
                    <span className="footer-packagetypetext">Feburary</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','March')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="March"
                    />
                    <span className="footer-packagetypetext">March</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','April')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="April"
                    />
                    <span className="footer-packagetypetext">April</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','May')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="May"
                    />
                    <span className="footer-packagetypetext">May</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','June')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="June"
                    />
                    <span className="footer-packagetypetext">June</span>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','July')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="July"
                    />
                    <span className="footer-packagetypetext">July</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','August')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="August"
                    />
                    <span className="footer-packagetypetext">August</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','September')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="September"
                    />
                    <span className="footer-packagetypetext">September</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','October')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="October"
                    />
                    <span className="footer-packagetypetext">October</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','November')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="November"
                    />
                    <span className="footer-packagetypetext">November</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      alignItems: "center",
                    }}
                  onClick={() => handleRedirection('Seasonally','December')}
                  >
                    <img
                      className="footer-location-icon"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/white_location_icon.png"
                      alt="December"
                    />
                    <span className="footer-packagetypetext">December</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 onClick={() => handleDetailsClick(5)} className={
                  showDetail === 5
                    ? "activeHeader"
                    : ""
                }>Talk to Us  <img src={DropDown} alt="dropdown"/></h2>
              <div
                className={
                  showDetail === 5
                    ? "active footerDetailsViewLast"
                    : "footerDetailsViewLast"
                }
              >
                <div className="email-details-collector">
                  <img
                    className="emaillogo"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/emailicon.png"
                    alt="Email"
                    width='14' height='14'
                  />
                  <a href="mailto:info@journeyrouters.com">info@journeyrouters.com</a>
                </div>
                <div className="email-details-collector">
                  <img
                    className="emaillogo"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/teleicon.png"
                    alt="phone1"
                    width='14' height='14'
                  />
                  <a href="tel:9999935176">99999-35176</a>
                </div>
                <div className="email-details-collector">
                  <img
                    className="emaillogo"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/whatappicon.png"
                    alt="phonr2"
                    width='14' height='14'
                  />
                  <a href="https://wa.me/+918368045646" target="blank">83680-45646</a>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom-parent-div">
            <div className="footer-bottom-holder">
              <h3>Social</h3>
              <div style={{display:'flex', marginTop: '1rem'}}>
                <a href="https://www.linkedin.com/company/journeyrouters" target="blank">
                  <img
                    className="footer-bottom-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/LinkedinIcon.png"
                    alt="linkedIn"
                    width='14' height='14'
                  />
                </a>
                <a href="https://www.instagram.com/journeyrouters" target="blank">
                  <img
                    className="footer-bottom-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/InstaIcon.png"
                    alt="Instagram"
                    width='14' height='14'
                  />
                </a>
                <a href="https://www.facebook.com/JourneyRouters" target="blank">
                  <img
                    className="footer-bottom-icon"
                    src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/FacebookIcon.png"
                    alt="Facebook"
                    width='14' height='14'
                  />
                </a>
              </div>
            </div>
            <div className="footer-bottom-holder">
              <h3>App</h3>
              <div style={{display:'flex', marginTop: '1rem'}}>
                <img
                  className="footer-bottom-icon"
                  src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/AppleIcon.png"
                  alt="appIconApple"
                  width='14' height='14'
                />
                <br />
                <img
                  className="footer-bottom-icon"
                  src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/AndroidIcon.png"
                  alt="appIconAndroid"
                  width='14' height='14'
                />
              </div>
            </div>
            <div className="footer-bottom-holder">
              <h3>Policy</h3>
              <Link to={'/Support'}>
                <span>FAQs | T&C</span>
              </Link>
            </div>
            {size.width >= 768 && (
            <FooterInput/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
