import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allDestinations: [],
};

export const destinationSlice = createSlice({
  name: "destinations",
  initialState,
  reducers: {
    setAllStoreDestinationList: (state, action) => {
      state.allDestinations=action.payload;
    },
  },
});

export const { setAllStoreDestinationList } = destinationSlice.actions;

export default destinationSlice.reducer;
