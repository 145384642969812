import { DownloadIcon } from 'lucide-react';
import React from 'react';
import './downloadVoucher.css'
// import DownloadIcon from '@mui/icons-material/Download';

const DownloadVoucher = () => {
  const vouchers = [
    { name: 'Hotel Voucher', value: 'Hotel'},
    { name: 'Land Voucher', value: 'Land' },
    { name: 'Transfer Voucher', value: 'Transfer' },
  ];

  return (
    <div className="download_voucher">
      {vouchers.map((voucher, index) => (
        <div key={index} className="voucher_item">
          <span className="voucher_dot">•</span>
          <span className="voucher_name">{voucher.name}</span>
          <DownloadIcon className="download_icon" />
        </div>
      ))}
    </div>
  );
};

export default DownloadVoucher;
