import React, { forwardRef, useEffect, useRef, useState } from "react";
import "./ItinearPdfCss.css";
import FrontImg from "../../Assets/pdf_front_img.png";
import PdfLogo from "../../Assets/pdflogo.svg";
import itinearyImgBottom from "../../Assets/bottom_img_pdf.png";
import { PDFExport } from "@progress/kendo-react-pdf";

import { CircularProgress } from "@mui/material";

const ItinearyPdf = forwardRef((props, ref) => {
  const [PackageData, setPackageData] = useState(props.DataPassed);
  const [loader, setLoader] = useState(false);
  const pdfExportComponent = useRef(null);
  const [layoutSelection, setLayoutSelection] = useState({
    sapn: "A4",
    value: "size-a4",
  });

  async function handleExportWithComponent() {
    setLoader(true);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save(); // Call the method after PDF is downloaded
    }
    setLoader(false);
  }
  // function extractLastPartWithoutExtension(url) {
  //   const parts = url.split("/");
  //   const lastPart = parts[parts.length - 1];
  //   const lastDotIndex = lastPart.lastIndexOf(".");
  //   if (lastDotIndex === -1) return lastPart;
  //   return lastPart.substring(0, lastDotIndex);
  // }

  useEffect(() => {
    const element = document.getElementsByClassName("pdf_centralise")[0];
    if (!element) return;

    const handleScroll = () => {
      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight;
      const clientHeight = element.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight) {
        // The element has been scrolled to the bottom
        handleElementAtBottom();
      }
    };

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleElementAtBottom = () => {
    // Calling method to do something here
  };
  return (
    <>
      <PDFExport
        ref={pdfExportComponent}
        fileName={PackageData.PackageName}
        forcePageBreak=".page-break"
      >
        <div className={`pre ${layoutSelection.value} pdf_centralise`}>
          <div className="Pdf_itineary-front_page">
            <div className="Pdf_logo_div">
              <img src={PdfLogo} alt="" />
            </div>
            <div className="pdf_front_titles">
              <h1>Journey Routers</h1>
              <h3>travel with us</h3>
            </div>
            <img src={FrontImg} alt="" />
          </div>
          <div className="Pdf_itineary_second_page page-break">
            <div className="itineary_top-section">
              <img
                src="https://images.pexels.com/photos/26555831/pexels-photo-26555831/free-photo-of-iceberg-on-sea-shore.png?auto=compress&cs=tinysrgb&w=600&lazy=load&lazy=load"
                alt=""
              />
              <div className="itineary_top_title">
                <p className="itineary_over_img_little_title">Brief</p>
                <p className="itineary_over_img_big_title">Itinerary</p>
              </div>
              <div className="itineary_img_bottom">
                <img src={itinearyImgBottom} alt="" className="" />
              </div>
            </div>
            <div className="contact_with_logo">
              <div className="contact">
                <p>Call for more Information</p>
                <p>+91- 8178413150</p>
              </div>
              <img src={PdfLogo} alt="" />
            </div>
            <div className="itineary_details_contnent">
              {PackageData?.Itineary?.map((item, index) => (
                <div className="daywise_itineary_plane" key={index}>
                  <span className="Day-wise-counter">0{item.Day}</span>
                  <div
                    className={
                      PackageData?.Itineary.length - 1 === index
                        ? "hidden"
                        : "Day-wise-line-and-card"
                    }
                  >
                    <div className="Day-wise-line"></div>
                  </div>
                  <div
                    className={
                      index % 2 === 0
                        ? "Day-wise-DayCard_activity_card left_day_wise_content"
                        : "Day-wise-DayCard_activity_card right_day_wise_content"
                    }
                  >
                    <p>{item.ActivityName}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="bottome_site_link">
              <a href="https://www.journeyrouters.com">
                www.journeyrouters.com
              </a>
            </div>
          </div>
          <div className="Pdf_itineary_third_page page-break">
            <div className="itineary_top-section">
              <img
                src="https://images.pexels.com/photos/26555831/pexels-photo-26555831/free-photo-of-iceberg-on-sea-shore.png?auto=compress&cs=tinysrgb&w=600&lazy=load&lazy=load"
                alt=""
              />
              <div className="itineary_top_title">
                <p className="itineary_over_img_little_title">Detailed</p>
                <p className="itineary_over_img_big_title">Itinerary</p>
              </div>
              <div className="itineary_img_bottom">
                <img src={itinearyImgBottom} alt="" className="" />
              </div>
            </div>
            <div className="contact_with_logo">
              <div className="contact">
                <p>Call for more Information</p>
                <p>+91- 8178413150</p>
              </div>
              <img src={PdfLogo} alt="" />
            </div>
            {PackageData?.Itineary?.map((item, index) => (
              <div className="third_page_content" key={index}>
                <div className="itineary_details_pdf">
                  <div className="itineary_details_img">
                    <img src={item.ActivityImg} alt={item.ActivityName} />
                  </div>
                  <div className="itineary_details_content">
                    <h2>Day {item.Day}</h2>
                    <h2>{item.ActivityName}</h2>
                    {item?.Descriptions?.split(/[\n.]+/)
                      .filter((data) => data.trim() !== "") // Filter out empty or whitespace strings
                      .map((data, index) => (
                        <p key={index}>{data}</p>
                      ))}
                  </div>
                </div>
              </div>
            ))}
            <div className="bottome_site_link">
              <a href="https://www.journeyrouters.com">
                www.journeyrouters.com
              </a>
            </div>
          </div>
          {PackageData?.OtherInclusionList ? (
            <div className="Pdf_itineary_third_page page-break">
              <div className="itineary_top-section">
                <img
                  src="https://images.pexels.com/photos/26555831/pexels-photo-26555831/free-photo-of-iceberg-on-sea-shore.png?auto=compress&cs=tinysrgb&w=600&lazy=load&lazy=load"
                  alt=""
                />
                <div className="itineary_img_bottom">
                  <img src={itinearyImgBottom} alt="" />
                </div>
              </div>
              <div className="contact_with_logo">
                <div className="contact">
                  <p>Call for more Information</p>
                  <p>+91- 8178413150</p>
                </div>
                <img src={PdfLogo} alt="" />
              </div>
              <div className="pdf_inclusions_exclusions">
                <h2>
                  <span>Package</span> Inclusions
                </h2>
                <div>
                  {PackageData?.InclusionList.map((data, index) => (
                      <p className="inclusion_para" key={index}>
                        {data}
                      </p>
                    ))}
                  {PackageData?.OtherInclusionList.map((data, index) => (
                      <p className="inclusion_para" key={index}>
                        {data}
                      </p>
                    ))}
                </div>
                <h2>
                  <span>Package</span> Exclusions
                </h2>
                <div>
                  {PackageData?.ExclusionList.map((data, index) => (
                      <p className="inclusion_para" key={index}>
                        {data}
                      </p>
                    ))}
                  {PackageData?.OtherExclusionList.map((data, index) => (
                      <p className="inclusion_para" key={index}>
                        {data}
                      </p>
                    ))}
                </div>
              </div>
              <div className="bottome_site_link">
                <a href="https://www.journeyrouters.com">
                  www.journeyrouters.com
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="Pdf_itineary_third_page page-break">
            <div className="itineary_top-section">
              <img
                src="https://images.pexels.com/photos/26555831/pexels-photo-26555831/free-photo-of-iceberg-on-sea-shore.png?auto=compress&cs=tinysrgb&w=600&lazy=load&lazy=load"
                alt=""
              />
              <div className="itineary_img_bottom">
                <img src={itinearyImgBottom} alt="" />
              </div>
            </div>
            <div className="contact_with_logo">
              <div className="contact">
                <p>Call for more Information</p>
                <p>+91- 8178413150</p>
              </div>
              <img src={PdfLogo} alt="" />
            </div>
            <div className="pdf_payment_page">
              <h2>
                <span>Payment</span> Details
              </h2>
              <div className="outer_payment_box">
                <div className="pdf_payment_box">
                  <p>Beneficiary Name: Journey Routers</p>
                  <p>Account No. : 113605000773</p>
                  <p>Account Type : Current</p>
                  <p>Bank Name : ICICI Bank</p>
                  <p>Branch: Malviya Nagar</p>
                  <p>IFSC Code: ICICINBBNRI</p>
                </div>
              </div>
              <h2>
                <span>Cancellation</span> Details
              </h2>
              <div className="pdf_cancellation_policy">
                <p>Flight- As per airline</p>
                <p>Hotel Refundable as per hotel policy</p>
                <p>
                  Land Part- 80% refundable before 25 days of travel start date{" "}
                </p>
                <p>
                  Land Part- No refund if canceled within 20 days of travel
                  start date{" "}
                </p>
              </div>
            </div>
            <div className="bottome_site_link">
              <a href="https://www.journeyrouters.com">
                www.journeyrouters.com
              </a>
            </div>
          </div>
          <div className="Pdf_itineary_fourth_page page-break">
            <div className="contact_with_logo">
              <div className="contact">
                <p>Call for more Information</p>
                <p>+91- 8178413150</p>
              </div>
              <img src={PdfLogo} alt="" />
            </div>
            <div className="about_pdf_img">
              <img
                className="about_img_child "
                src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/About1.png"
                alt=""
              />
              <img
                className="about_img_child "
                src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/About2.png"
                alt=""
              />
              <img
                className="about_img_child "
                src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/About3.png"
                alt=""
              />
              <img
                className="about_img_child "
                src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/About4.png"
                alt=""
              />
            </div>
            <div className="about_pdf_content">
              <h2>
                <span>About</span> Us
              </h2>
              <h4 className="about_pdf_title">
                Unlock the World's Wonders with Us!
              </h4>
              <p className="about_pdf_para">
                At Journey Routers, our aim is to create a vacation that's both
                adventurous and personal. We’re dedicated to crafting a travel
                experience that perfectly aligns with your standards and
                expectations for a fun-filled getaway. With us, you won't miss
                out on any options. Count on our expert, real-time advice to
                help you find the best customized yet affordable holiday
                packages. Our extensive knowledge of the island— its beaches,
                cultural spots, nature and wildlife parks, and historical
                sites—combined with our creativity, ensures a unique and
                unforgettable experience.
              </p>
            </div>
            <div className="bottome_site_link">
              <a href="https://www.journeyrouters.com">
                www.journeyrouters.com
              </a>
            </div>
          </div>
        </div>
      </PDFExport>
      <div className="download_btn_pdf" >
      {loader ? <CircularProgress size={20} /> : <button onClick={() => handleExportWithComponent()}>Download</button>}
      </div>
    </>
  );
})
export default ItinearyPdf;
