import { configureStore } from "@reduxjs/toolkit";
import destinationReducer from "../features/Destination/destinationSlice";
import loaderReducer from "../features/loader/loaderSlice";
import itninearyReducer from "../features/itnineary/itninearySlice";
import profileReducer from "../features/Profile/profileSlice";
import queryFetecherReducer from "../features/Queryfetcher/queryFetcherModalSlice";
export const store = configureStore({
  reducer: {
    destinations: destinationReducer,
    loader: loaderReducer,
    itninearypackages: itninearyReducer,
    profile: profileReducer,
    queryfetcher: queryFetecherReducer
  },
});
