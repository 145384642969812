import React, { useState, useEffect } from "react";
import "./BugHunter.css";
import { toast } from "react-toastify";
import axios from "axios";
import useAuthHook from "../../Hooks/useAuthHook";

const Url =
  "https://2rltmjilx9.execute-api.ap-south-1.amazonaws.com/DataTransaction/BugHunter";

const BugHunter = () => {
  const {  user } = useAuthHook();
  const initialFormData = {
    Title: "",
    Description: "",
    ScreenshotUrl: "",
    ReportedBy: user?.PhoneNumber,
    DateReported: new Date().toISOString(),
  };
  const [formData, setFormData] = useState(initialFormData);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const PostHunter = async (data) => {
    setSuccess(false);
    try {
      await axios.post(Url, data);
      setSuccess(true);
      setError(false);
      setTimeout(() => {
        setIsFormVisible(!isFormVisible);
        setFormData(initialFormData);
      }, 1500);
    } catch (error) {
      setSuccess(false);
      toast.error("Something went wrong!", error);
      console.log(error);
    }
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    setSuccess(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, ScreenshotUrl: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        const reader = new FileReader();
        reader.onload = (event) => {
          setFormData({ ...formData, ScreenshotUrl: event.target.result });
        };
        reader.readAsDataURL(blob);
      }
    }
  };

  const handleRemoveImage = () => {
    setFormData({ ...formData, ScreenshotUrl: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await PostHunter(formData);
  };



  useEffect(() => {
    if (user?.PhoneNumber) {
      setFormData((prevData) => ({
        ...prevData,
        ReportedBy: user.PhoneNumber,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (isFormVisible) {
      document.addEventListener("paste", handlePaste);
    } else {
      document.removeEventListener("paste", handlePaste);
    }
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [isFormVisible]);

  return (
    <>
      <div className="floating-button" onClick={toggleFormVisibility}>
        +
      </div>

      <div className={`form-container ${isFormVisible ? "visible" : ""}`}>
        <form className="bughunter-form" onSubmit={handleSubmit}>
          {success ? (
            <div>Form Successfully Submitted</div>
          ) : (
            <>
              <h2>Bug Report</h2>
              <div className="form-group">
                <label>Screenshot</label>
                <div
                  className="ImgUploader_BugHunter"
                  onPaste={handleFileChange}
                >
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
                {formData.ScreenshotUrl && (
                  <div className="image-preview">
                    <img
                      src={formData.ScreenshotUrl}
                      alt="Screenshot preview"
                    />
                    <button
                      type="button"
                      className="remove-button"
                      onClick={handleRemoveImage}
                    >
                      Remove Image
                    </button>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  name="Title"
                  value={formData.Title}
                  onChange={handleInputChange}
                  placeholder="Enter the bug title"
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="Description"
                  value={formData.Description}
                  onChange={handleInputChange}
                  placeholder="Describe the bug in detail"
                />
              </div>

              <button type="submit" className="submit-button">
                {!success ? "Submit" : "Loading...."}
              </button>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default BugHunter;
