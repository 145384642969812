import { createSlice } from '@reduxjs/toolkit';

const itninearySlice = createSlice({
  name: 'itninearypackages',
  initialState: {
    data: null,//Iteneray page  Data storage
    loading: false,
    error: null,
  },
  reducers: {
    setPackageData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setPackageData, setLoading, setError } = itninearySlice.actions;

export default itninearySlice.reducer;
