import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    destinationName: '',
    isOpenClose: false,
}

export const queryFetcherSlice = createSlice({
    name: 'queryFetcher',
    initialState,
    reducers: {
        setDestinationName : (state, action) =>{
            state.destinationName = action.payload
        },
        setQueryFetcher : (state, action) => {
            state.isOpenClose = action.payload
        },
    }
})

export const { setDestinationName, setQueryFetcher } = queryFetcherSlice.actions

export default queryFetcherSlice.reducer