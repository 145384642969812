import imageCompression from "browser-image-compression";

const compressImageToBase64 = async (imageFile) => {
  if (!imageFile) return null;

  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 680,
    useWebWorker: true,
  };

  try {
    // Compress the image
    const compressedFile = await imageCompression(imageFile, options);

    // Convert the compressed image to Base64
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String); // Return Base64 string
      };
      reader.onerror = reject; // Handle errors during reading
      reader.readAsDataURL(compressedFile);
    });
  } catch (error) {
    console.error("Error compressing image:", error);
    throw error; // You can handle this error in the calling code
  }
};

export default compressImageToBase64;
