class SnackbarService {
  constructor() {
    this.listeners = [];
  }

  subscribe(listener) {
    this.listeners.push(listener);
  }

  unsubscribe(listener) {
    this.listeners = this.listeners.filter((item) => item !== listener);
  }

  notify(snackbarData) {
    this.listeners.forEach((listener) => listener(snackbarData));
  }

  showSnackbar(content, type = "success") {
    this.notify({ content, type, open: true });
    setTimeout(() => {
      this.notify({ content: "", type: "", open: false });
    }, 5000);
  }

  showSuccess(content) {
    this.showSnackbar(content, "success");
  }

  showError(content) {
    this.showSnackbar(content, "error");
  }
}

const snackbarService = new SnackbarService();
export default snackbarService;
