import Footer from "../Footer/Footer";
import "./NewItinearyPage.css";
import ItinearyHero from "./itenearyNewComponents/ItinearyHero";
import ItearnearyMain from "./itenearyNewComponents/ItearnearyMain";
import NavbarNew from "../Navbar/NavbarNew";

const ItinearyDesktopPage = () => {
  return (
    <>
      <NavbarNew/>
      <ItinearyHero />
      <ItearnearyMain />
      <Footer />
    </>
  );
};

export default ItinearyDesktopPage;
