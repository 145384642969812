import React, { Fragment, useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import axios from "axios";
import './Blogs.css'
import { BASE_URL } from "../../utils";
import { Link, useParams } from "react-router-dom";

const SuggestionCardBlogDetailPage = ({ destination, sightseeing }) => {
  const [cardData, setCardData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const fetchSuggestion = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}DataTransaction/${
            !sightseeing ? "Blogs" : "sightseeing"
          }`,
          {
            params: {
              DestinationName: "all",
              Limit: 3,
              LastEvaluatedKey: {
                [sightseeing ? "SightId" : "PostId"]: id,
                DestinationName: destination,
              },
            },
          }
        );
        const fetchedBlogs = sightseeing
          ? response?.data?.Data
          : response?.data?.blogs;
        setCardData(fetchedBlogs);
      } catch (error) {
        console.error("Failed to fetch Blogs", error);
      }
    };
    fetchSuggestion();
  }, [destination, id, sightseeing]);

  return (
    <div className="blogsections">
      <h1>More From {sightseeing ? "Sightseeing" : "Blog"}</h1>
      <div className="featureOuter">
        <div className="feature-type-main-div">
          {cardData?.map((ele, id) => (
          <Link
          key={id}
          to={`/${sightseeing ? "sightseeing" : "blogs"}/${sightseeing ? ele?.SightId : ele?.PostId}?destination=${ele?.DestinationName}`}
          className="blogCardWrapper"
        >
          <BlogCard data={ele} />
        </Link>
        
          ))}
        </div>
      </div>
    </div>
  );
  
};

export default SuggestionCardBlogDetailPage;
