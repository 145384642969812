import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "@mui/material";
import "./navbar.css";
import "../HomePage/HomePage.css";
import logo from "../../Assets/Logo.png";
import useWindowSize from "../../Hooks/useWindowSize";
import SidebarDrawer from "./SidebarDrawer";
import { getInitials } from "../../utils/NameUtils";
import useAuthHook from "../../Hooks/useAuthHook";
import AuthModal from "../Auth/AuthModal";

const Navbar = ({ isSticky, isDark }) => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const { user } = useAuthHook();

  const [open, setOpen] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const fetchDestinations = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}DataTransaction/GetAllDestinationList`
      );
      const fetchedDestinations = response?.data;
      sessionStorage.setItem(
        "DestinationsLocHome",
        JSON.stringify(fetchedDestinations)
      );
      setDestinations(fetchedDestinations);
    } catch (error) {
      console.error("Failed to fetch destinations", error);
    }
  };

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  
  const navigateToLocation = (data) => {
    setOpen(false);
    if (data === currentLocation) {
      setTimeout(() => setOpen(false), 100);
    } else {
      navigate(`/Destination/${data.DestinationName}`);
      setCurrentLocation(data);
    }
  };

  useEffect(() => {
    const cachedDestinations = sessionStorage.getItem("DestinationsLocHome");
    if (cachedDestinations) {
      setDestinations(JSON.parse(cachedDestinations));
    } else {
      fetchDestinations();
    }
  }, []);


  const domesticDestinations = destinations?.filter(
    (dest) => dest.Type === "Domestic"
  );
  const internationalDestinations = destinations?.filter(
    (dest) => dest.Type === "International"
  );

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen); 
  };

  return (
    <div className="flex cursor-pointer relative mainnavwrapper bottom-[12px] items-center justify-between px-[10px]">
      <Link to={"/"} className="logo">
        <img className="" src={logo} alt="Logo" />
      </Link>

      {!isSticky ? (
        <div className="Two_Row_NavBar_Parent">
          <div style={{ color: isDark ? "black" : "white" }} className="FirstRow_NavLinks">
            <Link className="Link">
            <h3>About Us</h3>
          </Link>
            <Link >Career</Link>
            <Link >Call.on:-9876543210</Link>
            <h3> Socail Media Logo</h3>
          </div>
          <div style={{ color: isDark ? "black" : "white" }} className="NavLinks">
            {/* <Link to="/" className="Link">
            <h3>Home</h3>
          </Link> */}
            {/* <Link to="/searchResult?Category=Honeymoon">Honeymoon Gateways</Link> */}
            <Link onMouseEnter={handleOpenModal}>Honeymoon Gateways</Link>
            <h3 onMouseEnter={handleOpenModal}> Explore Destinations</h3>
            <Link to="/searchResult?OnSellFlg=true">Best Deals</Link>
            <Link >MICE</Link>
            {/* <Link to="/Support">Support</Link> */}
          </div>
        </div>
        ) : (
        <Link to="/SearchFilter" id="Link">
          <div className="searchbar2 relative right-[6px]">
            <img
              className="searchIcon"
              src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/SearchIcon.png"
              alt=""
            />
            <p className="SearchPlacholder sm:mr-[32px] text-[24px]">
              {size.width > 638
                ? "Search where would you like to go ?"
                : "Search"}
            </p>
          </div>
        </Link>
      )}

      <Modal
        open={open}
        onClose={handleCloseModal}
        className="DestinationModal"
      >
        <div
          onMouseLeave={handleCloseModal}
          className="DestinationMapperModal px-6 py-6"
        >
          <div className="flex-col flex-[5] flex items-center">
            <h1 className="text-3xl relative right-9 font-bold">Domestic</h1>
            <div className="flex gap-2 flex-wrap">
              {domesticDestinations.map((data, index) => (
                <span
                  onClick={() => navigateToLocation(data)}
                  className="text-xl hover:text-red-700 cursor-pointer relative top-9 left-9 text-gray-800 font-bold"
                  key={index}
                  style={{ flex: "0 0 40%", maxWidth: "40%" }}
                >
                  {data.DestinationName}
                </span>
              ))}
            </div>
          </div>
          <div className="w-[2px] h-full relative right-[8%] bg-gray-100"></div>
          <div className="flex-col flex-[5] flex items-center">
            <h1 className="text-3xl relative right-9 font-bold">
              International
            </h1>
            <div className="flex gap-1 flex-wrap">
              {internationalDestinations.map((data, index) => (
                <span
                  onClick={() => navigateToLocation(data)}
                  className="text-xl cursor-pointer hover:text-red-700 relative top-9 left-9 text-gray-800 font-bold"
                  key={index}
                  style={{ flex: "0 0 40%", maxWidth: "40%" }}
                >
                  {data.DestinationName}
                </span>
              ))}
            </div>
          </div>
        </div>
      </Modal>

      <div className="flex  itmlist items-center navButtons">
      { size.width>900 && <>
        {user  ? (
          <Link to={"/Profile/myAccount"}>
            <div className="ProfileAvatar w-[2rem] h-[2rem] rounded-full">
              <p>{getInitials(user?.Name || "User")}</p>
            </div>
          </Link>
        ) : (
          
          <AuthModal />
        )}
        
        </>}

        <div className=" w-[10rem] h-[15rem] md:hidden  flex justify-end items-center" onClick={toggleDrawer}>

          <svg
            className="block sm:hidden"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
          >
            <path
              d="M1.16815 9.30311H14.9021M1.16815 5.15882H14.9021M1.16815 1.01453H14.9021"
              stroke={isDark || isSticky ? "#000000" : "#F5F5F5"}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      <SidebarDrawer isOpen={drawerOpen} toggleDrawer={toggleDrawer} domesticDestinations={domesticDestinations}
        internationalDestinations={internationalDestinations} />
    </div>
  );
};

export default Navbar;
