import React, { useState } from "react";
import "./Login.css";
import loginbg from "../../Assets/loginbg.svg";
import logo from "../../Assets/Logo.png";
import useAuthHook from "../../Hooks/useAuthHook";

const ForgetPassword = ({ onLoginClick }) => {
  const { authApi, okres } = useAuthHook();
  const [formValues, setFormValues] = useState({
    PhoneNumber: "",
    Otp: "",
    NewPassword: "",
  });

  // Handle input change for phone number, OTP, and new password
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "PhoneNumber" && value.length > 10) {
      return; // Prevent typing more than 10 digits
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Handle click to send OTP

  const handleSendOtpClick = async () => {
    const credentials = {
      action: "password-reset",
    };
    if (formValues.PhoneNumber !== "") {
      await authApi({ ...credentials, ...formValues });
    }
  };

  // Handle OTP and new password verification
  const handleResetPasswordClick = async () => {
    const credentials = {
      action: "password-reset",
      type: "verify",
    };

    await authApi({ ...credentials, ...formValues });
  };

  return (
    <div className="ForgotWrappermain relative">
      <img className="loginlogo" src={logo} alt="" />
      <div className="LoginMainTitle">Your Next Great Experience.</div>
      <div className="LoginWrapper relative">
        <div className="LoginForm">
          {/* Phone Number Input */}
          <div className="flex loginformdiv items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
            >
              <path
                d="M5.8522 0.7728C5.8522 0.7728 5.5207 0 5.01442 0C4.50813 0 4.257 0.2292 4.08422 0.3872C3.91144 0.5452 1.0204 2.91 1.0204 2.91C1.0204 2.91 0.179812 3.6408 0.2437 5.016C0.297945 6.3912 0.567963 8.348 1.97029 11.074C3.36257 13.794 6.84749 17.95 9.04902 19.4108C9.04902 19.4108 11.0894 20.9688 12.9876 21.6016C13.5389 21.7748 14.6418 21.9996 14.899 21.9996C15.1562 21.9996 15.6211 21.9996 16.1506 21.6144C16.6891 21.2256 19.7095 18.8076 19.7095 18.8076C19.7095 18.8076 20.4488 18.1424 19.5902 17.3696C18.7275 16.5964 16.1077 14.8776 15.5471 14.4256C14.9854 13.9668 14.1862 14.1688 13.8406 14.4796C13.4959 14.7924 12.8803 15.3076 12.8048 15.3724C12.6922 15.4584 12.3837 15.7372 12.0381 15.5984C11.5981 15.4252 9.79398 14.4504 8.12123 12.1724C6.45893 9.8964 6.27611 9.1524 6.02779 8.3484C5.941 8.1036 6.03462 7.832 6.25401 7.6916C6.50313 7.5196 7.42047 6.7588 7.42047 6.7588C7.42047 6.7588 8.01395 6.1756 7.76603 5.4888C7.51811 4.802 5.85139 0.7728 5.85139 0.7728H5.8522Z"
                fill="#FE6F61"
              />
            </svg>
            <input
              type="number"
              min="1"
              max="10"
              name="PhoneNumber"
              value={formValues.PhoneNumber}
              onChange={handleInputChange}
              placeholder="Contact Number"
            />
          </div>
          {formValues.PhoneNumber === "" &&
          <span className="text-red-400 errmsgforgotpassword">
             Please Enter Mobile Number
          </span>
}
          {/* Conditionally render the OTP and new password input fields if OTP has been sent */}
          {okres === true && (
            <>
              {/* OTP Input */}
              <div className="flex loginformdiv items-center">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="29"
                  viewBox="0 0 26 29"
                  fill="none"
                >
                  <path
                    d="M20.9913 10.8749H18.8499V7.85421C18.8499 4.1839 16.2134 1.2085 12.9613 1.2085H11.8907C8.63851 1.2085 6.00207 4.1839 6.00207 7.85421V10.8749H3.86061C3.26927 10.8749 2.79004 11.4158 2.79004 12.0831V26.5831C2.79004 27.2505 3.26927 27.7913 3.86061 27.7913H20.9913C21.5827 27.7913 22.0619 27.2505 22.0619 26.5831V12.0831C22.0619 11.4158 21.5827 10.8749 20.9913 10.8749ZM8.14321 7.85421C8.14321 5.51862 9.82086 3.62492 11.8907 3.62492H12.9613C15.0308 3.62492 16.7087 5.51826 16.7087 7.85421V10.8749H8.14321V7.85421ZM7.60777 21.1456C6.72092 21.1456 6.00175 20.334 6.00175 19.3331C6.00175 18.3323 6.72092 17.5206 7.60777 17.5206C8.49461 17.5206 9.21378 18.3323 9.21378 19.3331C9.24398 20.3003 8.57363 21.1116 7.71665 21.1456C7.68036 21.1471 7.64406 21.1471 7.60777 21.1456ZM12.4258 21.1456C11.539 21.1456 10.8198 20.334 10.8198 19.3331C10.8198 18.3323 11.539 17.5206 12.4258 17.5206C13.3127 17.5206 14.0318 18.3323 14.0318 19.3331C14.062 20.3003 13.3917 21.1116 12.5347 21.1456C12.4984 21.1471 12.4621 21.1471 12.4258 21.1456ZM17.2439 21.1456C16.357 21.1456 15.6378 20.334 15.6378 19.3331C15.6378 18.3323 16.357 17.5206 17.2439 17.5206C18.1307 17.5206 18.8499 18.3323 18.8499 19.3331C18.8801 20.3003 18.2097 21.1116 17.3528 21.1456C17.3165 21.1471 17.2802 21.1471 17.2439 21.1456Z"
                    fill="#fe6f61"
                  />
                </svg>
                <input
                  type="text"
                  name="Otp"
                  value={formValues.Otp}
                  onChange={handleInputChange}
                  placeholder="Enter OTP"
                />
              </div>

              {/* New Password Input */}
              <div className="flex loginformdiv items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="29"
                  viewBox="0 0 26 29"
                  fill="none"
                >
                  <path
                    d="M20.9913 10.8749H18.8499V7.85421C18.8499 4.1839 16.2134 1.2085 12.9613 1.2085H11.8907C8.63851 1.2085 6.00207 4.1839 6.00207 7.85421V10.8749H3.86061C3.26927 10.8749 2.79004 11.4158 2.79004 12.0831V26.5831C2.79004 27.2505 3.26927 27.7913 3.86061 27.7913H20.9913C21.5827 27.7913 22.0619 27.2505 22.0619 26.5831V12.0831C22.0619 11.4158 21.5827 10.8749 20.9913 10.8749ZM8.14321 7.85421C8.14321 5.51862 9.82086 3.62492 11.8907 3.62492H12.9613C15.0308 3.62492 16.7087 5.51826 16.7087 7.85421V10.8749H8.14321V7.85421ZM7.60777 21.1456C6.72092 21.1456 6.00175 20.334 6.00175 19.3331C6.00175 18.3323 6.72092 17.5206 7.60777 17.5206C8.49461 17.5206 9.21378 18.3323 9.21378 19.3331C9.24398 20.3003 8.57363 21.1116 7.71665 21.1456C7.68036 21.1471 7.64406 21.1471 7.60777 21.1456ZM12.4258 21.1456C11.539 21.1456 10.8198 20.334 10.8198 19.3331C10.8198 18.3323 11.539 17.5206 12.4258 17.5206C13.3127 17.5206 14.0318 18.3323 14.0318 19.3331C14.062 20.3003 13.3917 21.1116 12.5347 21.1456C12.4984 21.1471 12.4621 21.1471 12.4258 21.1456ZM17.2439 21.1456C16.357 21.1456 15.6378 20.334 15.6378 19.3331C15.6378 18.3323 16.357 17.5206 17.2439 17.5206C18.1307 17.5206 18.8499 18.3323 18.8499 19.3331C18.8801 20.3003 18.2097 21.1116 17.3528 21.1456C17.3165 21.1471 17.2802 21.1471 17.2439 21.1456Z"
                    fill="#fe6f61"
                  />
                </svg>
                <input
                  type="password"
                  name="NewPassword"
                  value={formValues.NewPassword}
                  onChange={handleInputChange}
                  placeholder="Enter New Password"
                />
              </div>
            </>
          )}
        </div>

        <div className="absolute bottom-0">
          <div className="btnwrap">
            {okres ? (
              <div className="loginBtn2" onClick={handleResetPasswordClick}>
                Reset Password
              </div>
            ) : (
              <div className="loginBtn2" onClick={handleSendOtpClick}>
                Forgot Password
              </div>
            )}
            <button onClick={onLoginClick}>Login</button>
          </div>

          <img src={loginbg} alt="loginbg" />
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
