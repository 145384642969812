import React, { useState, useEffect } from 'react';
import CheckIcon from '../../Assets/snackBar_feather_check_circle.svg';
import ErrorIcon from '../../Assets/crossIcon.svg';
import './globalSnack.css';
import snackbarService from '../../Services/snackbarService';

const GlobalSnackbar = () => {
  const [snackbar, setSnackbar] = useState({ content: '', type: '', open: false });

  useEffect(() => {
    const handleSnackbar = (data) => {
      setSnackbar(data);
    };

    snackbarService.subscribe(handleSnackbar);
    return () => {
      snackbarService.unsubscribe(handleSnackbar);
    };
  }, []);

  if (!snackbar.open) return null;

  const icon = snackbar.type === 'error' ? ErrorIcon : CheckIcon;
  // const containerClass = snackbar.type === 'error' ? 'global_snacks_container error' : 'global_snacks_container success';

  return (
    <div className="global_snacks_container">
      <div className="global_snacks">
        <img src={icon} alt="" width={20} height={20} />
        <p>{snackbar.content}</p>
      </div>
    </div>
  );
};

export default GlobalSnackbar;

