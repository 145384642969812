import React, { useEffect, useState } from "react";
import axios from "axios";

const GalleryDesktop = () => {

  const [image, setImages] = useState([[], [], []]);
  const [page, setPage] = useState(1); // Track the current page
  const [hasMore, setHasMore] = useState(false); // Track if more data is available
  const [loadingApi, setLoadingApi] = useState(false);

  const fetchImages = async () => {
    try {
      setLoadingApi(true)
      const response = await axios.get("https://2rltmjilx9.execute-api.ap-south-1.amazonaws.com/DataTransaction/UserGallary?UserId=");
      const data = response.data;

      if (data.length === 0) {
        setHasMore(false); // No more data
      } else {
        distributeImages(data);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally{
      setLoadingApi(true)
    }
  };
  useEffect(() => {
    fetchImages();
  }, [page]);

  const distributeImages = (newImages) => {
    
    let newAllImges = [];
    // eslint-disable-next-line array-callback-return
    newImages.map((item) => {
      // eslint-disable-next-line array-callback-return
      item.Gallary.map((image)=>{
        newAllImges.push(image)
      })
    })
    shuffle(newAllImges)
    const columns = [...image];

    shuffle(newAllImges).forEach((image, index) => {
      columns[index % 3].push(image);
    });

    setImages(columns);
  };

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const loadMoreImages = () => {
    setPage((prevPage) => prevPage + 1); // Load the next page
  };
  return (
    <>
      {
        loadingApi ?
        <div className="gallery_centralise">
        <div className="gallery_outer_container">
        <div className="gallery-container">
          {image.map((column, columnIndex) => (
            <div key={columnIndex} className="gallery-column">
              {column.map((item, index) => (
                <div key={index} className="gallery-item">
                  
                  <img src={item.Img_url}  loading="lazy" alt={item.Img_Name} />
                </div>
              ))}
              {hasMore && <button onClick={loadMoreImages}>Load More</button>}
            </div>
          ))}
        </div>
        </div>
      </div> :
      <div className="top_shimmer" style={{width: '100%', height: '100vh', margin: '10px 0px !important'}}></div>
      }
    </>
  );
};

export default GalleryDesktop;