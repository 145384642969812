

import React, { useRef } from "react";
import Navbar from "../Navbar/navbar";
import ItinearyMobileHeroContainer from "./itenearyNewComponents/ItinearyMobileHeroContainer";
import "./NewItinearyPage.css";
import { Tab } from "./itenearyNewComponents/ItearnearyMain";
import ItinearyCancellationPolicy from "./itenearyNewComponents/ItinearyCancellationPolicy";
import Testimonials from "../Testimonial/Testimonials";
import PackageMobOverview from "./itenearyNewComponents/PackageMobOverview";
import DaywiseMobile from "./itenearyNewComponents/DaywiseMobile";
import InclusionExlusionDetail from "./itenearyNewComponents/InclusionExlusionDetail";
import ItenearyFAQ from "./itenearyNewComponents/ItenearyFAQ";
import ItninearyHelpSection from "./itenearyNewComponents/ItninearyHelpSection";
import ItninearyBookNowCard from "./itenearyNewComponents/ItninearyBookNowCard";
import ItinearyBookBtn from "./itenearyNewComponents/ItinearyBookBtn";
import ItinerayPlanning from "./itenearyNewComponents/ItinerayPlanning";

const MobileTabs = [
  { label: "OverView", component: PackageMobOverview },
  { label: "Day wise Itinerary", component: DaywiseMobile },
  { label: "Cancellation Policy", component: ItinearyCancellationPolicy },
  { label: "Reviews", component: Testimonials },
];

const ItinearyMobilePage = () => {
  const sectionRefs = useRef([]);

  const handleScrollToSection = (index) => {
    const section = sectionRefs.current[index];

    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY-40;
      const offsetTop = sectionTop;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
    
      <Navbar isDark={true} />
    
      <ItinearyMobileHeroContainer />
      <div className="relative h-full">
        <div className="sticky bg-white py-2 mobiletabwarpper w-full h-full top-0 z-50">
          {MobileTabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              onClick={() => handleScrollToSection(index)}
            />
          ))}
        </div>

        <div ref={(el) => (sectionRefs.current[0] = el)}>
          <PackageMobOverview />
        </div>
        <div className="PackageMobCentalize" ref={(el) => (sectionRefs.current[1] = el)}>
          <DaywiseMobile />
          <InclusionExlusionDetail/>
        </div>
        <div className="PackageMobCentalize" ref={(el) => (sectionRefs.current[2] = el)}>
          <ItinearyCancellationPolicy />
          <ItenearyFAQ/>
          <ItninearyHelpSection/>
          <ItninearyBookNowCard/>
          <ItinearyBookBtn/>
          <ItinerayPlanning/>
        </div>
        <div  className="PackageMobCentalize h-[40vh] " ref={(el) => (sectionRefs.current[3] = el)}>
          <div className="test">

          <Testimonials />
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default ItinearyMobilePage;
