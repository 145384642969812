import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FlightsBgd from '../../Assets/SuuportImg/FlightsBgd.png';
import './Flights.css'
// import Navbar from '../Navbar/navbar';
import NavbarNew from '../Navbar/NavbarNew';
function FlightsHeader({ }) {
    const [open, setopen] = useState(false)
    const DestinationDomestic = [
        'Kerala',
        'Kashmir',
        'Ladakh',
        'Goa',
        'Himachal',
        'Rajasthan',
        'North East',
        'Andaman',
        'Rajasthan',
        'Sikkim',
        'Darjeeling',
    ]
    const DestinationInternation = [
        'Bali',
        'Dubai',
        'Malaysia',
        'Thailand',
        'Maldives',
        'Europe',
        'Singapore',
        'Vietnam',
        'Mauritius'
    ]
    function onClose() {
        setopen(false)
    }
    function HandleOpen() {
        setopen(true)
    }
    let navigate = useNavigate();
    useEffect(() => {
    }, [open])
    return (
        <div className='BackgrondImgFlights'>
            <div className="content">
               <NavbarNew/>
                <div className='SearchNavBar'>
                    <div className='yourSearchEndsHere'>Terms And Conditions</div>
                </div>
            </div>
        </div>
    );
}

export default FlightsHeader;