import React from "react";
import "../NewItinearyPage.css";
import { useSelector } from "react-redux";
import { scrollToDiv } from "../../ItinearyPage/ItinearyHeader/DayWise/Daywise";

const PackageHiglights = () => {
  const Package = useSelector((state) => state.itninearypackages.data);
  return (
    <div>
      <div className="text-[2rem] py-8 font-bold">Package Highlights</div>
      <div className="highlightsDiv">
        {Package?.Itineary?.filter(
          (data) => data?.packageHighLightFlag === true || data?.packageHighLightFlag === 'true'
        )
          ?.slice(0, 4)
          .map((data, index) => (
            <div
              key={index}
              className="highlightsDivBox cursor-pointer"
              onClick={() => scrollToDiv(data.Day)}
            >
              <img
                className="highlightsDivBoximg"
                src={data.ActivityImg}
                alt={`img-${index}`}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default PackageHiglights;
