import React from 'react';
import NoProductImg from '../../Assets/noProduct.svg'

function NoProduct() {
  return (
    <div className='no_Product_Cart'>
        <img src={NoProductImg} alt="" />
      <div className="text_no_product">
        <h2>Product</h2>
        <p>Go find products you like</p>
      </div>
    </div>
  )
}

export default NoProduct
